import * as React from 'react'
import { cloneElement } from 'react'
import {
    CreateButton,
    sanitizeListRestProps,
    TopToolbar,
    useListContext,
    usePermissions,
    useResourceDefinition,
} from 'react-admin'
import { ExportButton } from '../common/ExportButton'

export const UserListActions = (props) => {
    const { className, maxResults, filters, exportPath, ...rest } = props
    const { permissions } = usePermissions()
    const { total, filterValues, currentSort, basePath, resource, displayedFilters, showFilter } = useListContext()
    const { hasCreate } = useResourceDefinition()
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters &&
                cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
            {hasCreate && <CreateButton basePath={basePath} />}
            {permissions === 'ROLE_ADMIN' && (
                <ExportButton path={'customers'} pdf={true} total={total} sort={currentSort} filter={filterValues} />
            )}
            {permissions === 'ROLE_ADMIN' && (
                <ExportButton path={'customers'} pdf={false} total={total} sort={currentSort} filter={filterValues} />
            )}
        </TopToolbar>
    )
}
