import React from 'react'
import Button from '@mui/material/Button'
import ConfirmIcon from '@mui/icons-material/ThumbUp'

export function OrderConfirmButton({ order }) {
    return (
        <Button
            variant={'text'}
            disabled={order.status === 'CONFIRMED'}
            color={'secondary'}
            startIcon={<ConfirmIcon />}
        >
            Confirm
        </Button>
    )
}
