import React from 'react'
import { useRecordContext } from 'react-admin'

export const AddressField = ({ source }) => {
    const record = useRecordContext()
    if (!record || record[source] === 0 || record[source] === undefined) {
        return <span>NA</span>
    } else {
        let address = record[source]
        let string = address.comment + ' ' + address.town.name + ' ' + address.district.name + ' ' + address.city.name
        return <span>{string}</span>
    }
}
export const AddressString = (address) =>
    address.comment + ' ' + address.town.name + ' ' + address.district.name + ' ' + address.city.name
