import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import trLocale from 'date-fns/locale/tr'
import Stack from '@mui/material/Stack'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import React from 'react'

type Prop = {
    start: Date
    end: Date
    setStartDate: (date: Date | null) => void
    setEndDate: (date: Date | null) => void
    period: string
    setPeriod: (period: 'DAY' | 'MONTH' | 'YEAR') => void
}
export default function ChartCommonInput({ start, end, setStartDate, setEndDate, setPeriod, period }: Prop) {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={trLocale}>
            <Stack spacing={1} direction={'row'} sx={{ width: '100%', pr: 1, justifyContent: 'end' }}>
                <DatePicker
                    value={start}
                    format="dd/MM/yyyy"
                    slotProps={{ textField: { size: 'small' } }}
                    maxDate={new Date()}
                    label="Start Date"
                    views={['year', 'month', 'day']}
                    onChange={setStartDate}
                />

                <DatePicker
                    value={end}
                    format="dd/MM/yyyy"
                    label="End Date"
                    maxDate={new Date()}
                    slotProps={{ textField: { size: 'small' } }}
                    minDate={start}
                    views={['year', 'month', 'day']}
                    onChange={setEndDate}
                />

                <FormControl size={'small'}>
                    <InputLabel size={'small'} htmlFor="demo-controlled-open-select">
                        Period
                    </InputLabel>
                    <Select
                        value={period}
                        size={'small'}
                        label={'Period'}
                        onChange={(event) => {
                            // @ts-ignore
                            setPeriod(event.target.value)
                        }}
                        inputProps={{
                            name: 'period',
                            size: 'small',
                            id: 'demo-controlled-open-select',
                        }}
                    >
                        <MenuItem value={'DAY'}>Days</MenuItem>
                        <MenuItem value={'MONTH'}>Months</MenuItem>
                        <MenuItem value={'YEAR'}>Year</MenuItem>
                    </Select>
                </FormControl>
            </Stack>
        </LocalizationProvider>
    )
}
