import React, { useEffect, useState } from 'react'
import { RankUser } from '../types'
import {
    emptyRows,
    TableEmptyRows,
    TableHeadCustom,
    TableNoData,
    TablePaginationCustom,
    TableSkeleton,
    useTable,
} from 'src/components/table'
import TableContainer from '@mui/material/TableContainer'
import Scrollbar from 'src/components/scrollbar'
import Table from '@mui/material/Table'
import Card from '@mui/material/Card'
import TableBody from '@mui/material/TableBody'
import RankingHistoryRow from './ranking-history-row'
import { fetchJson } from '../../../rest/fetch'
import { SERVER_IP } from '../../../index'

type Prop = {
    challenge: number | string
}
const TABLE_HEAD = [
    { id: 'id', label: 'ID' },
    { id: 'title', label: 'Name' },
    { id: 'spent', label: 'Spent Amount' },
    { id: 'payback', label: 'Payback Amount' },
]

export default function RankingHistoryTableView({ challenge }: Prop) {
    const table = useTable()
    const [users, setUsers] = React.useState<RankUser[]>([])
    const [loading, setLoading] = useState(false)
    const notFound = !users.length
    const denseHeight = table.dense ? 52 : 72

    useEffect(() => {
        setLoading(true)
        const x = async () => {
            fetchJson(`${SERVER_IP}/app/rankings?id=${challenge}`)
                .then((response) => {
                    if (response.json) {
                        const d = response.json
                        const r = []
                        if (d.first != null) {
                            r.push(d.first)
                        }
                        if (d.second != null) {
                            r.push(d.second)
                        }
                        if (d.third != null) {
                            r.push(d.third)
                        }
                        setUsers(r.concat(d.others))
                    }
                })
                .catch(() => {
                    setUsers([])
                })
                .finally(() => {
                    setLoading(false)
                })
        }
        void x()
    }, [challenge])

    return (
        <>
            <Card sx={{ width: '100%' }}>
                <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                    <Scrollbar>
                        <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                            <TableHeadCustom
                                order={table.order}
                                orderBy={table.orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={users.length}
                                numSelected={table.selected.length}
                                onSort={table.onSort}
                            />

                            <TableBody>
                                {loading
                                    ? [...Array(table.rowsPerPage)].map((_, index) => (
                                          <TableSkeleton key={index} sx={{ height: denseHeight }} />
                                      ))
                                    : users
                                          .slice(
                                              table.page * table.rowsPerPage,
                                              table.page * table.rowsPerPage + table.rowsPerPage
                                          )
                                          .map((row) => <RankingHistoryRow key={row.id} user={row} />)}

                                <TableEmptyRows
                                    height={denseHeight}
                                    emptyRows={emptyRows(table.page, table.rowsPerPage, users.length)}
                                />

                                <TableNoData notFound={notFound && !loading} />
                            </TableBody>
                        </Table>
                    </Scrollbar>
                </TableContainer>

                <TablePaginationCustom
                    count={users.length}
                    page={table.page}
                    rowsPerPage={table.rowsPerPage}
                    onPageChange={table.onChangePage}
                    onRowsPerPageChange={table.onChangeRowsPerPage}
                    dense={table.dense}
                    onChangeDense={table.onChangeDense}
                />
            </Card>
        </>
    )
}
