import React, { useEffect, useState } from 'react'
import { Card, CircularProgress, ListItemAvatar, ListItemText } from '@mui/material'
import { fetchJson } from '../rest/fetch'
import { SERVER_IP } from '../index'
import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Star from '@mui/icons-material/Stars'
import ListItemButton from '@mui/material/ListItemButton'
import { useNavigate } from 'react-router-dom'
import List from '@mui/material/List'

const useStyles = makeStyles((theme) => ({
    main: {
        flex: '1',
        margin: '1em',
    },
    titleLink: { textDecoration: 'none', color: 'inherit' },
    card: {
        padding: '16px 0',
        overflow: 'inherit',
        textAlign: 'right',
        height: '470px',
    },
    title: {
        padding: '0 16px',
    },
    value: {
        padding: '0 16px',
        minHeight: 48,
    },
    avatar: {
        background: theme.palette.background.avatar,
    },
    formControl: {
        marginRight: '1em',
    },
}))

export function RankingCard() {
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null)
    const [rankings, setRankings] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true)
        fetchJson(`${SERVER_IP}/app/rankings`).then(
            (response) => {
                setLoading(false)
                if (response.json) {
                    let d = response.json
                    setData(d)
                    let r = []
                    if (d.first != null) {
                        r.push(d.first)
                    }
                    if (d.second != null) {
                        r.push(d.second)
                    }
                    if (d.third != null) {
                        r.push(d.third)
                    }
                    setRankings(r.concat(d.others))
                }
            },
            () => {
                console.log('Error occurred')
                setLoading(false)
            }
        )
    }, [])

    const onTap = (item) => {
        navigate(`/customers/${item.id}/show`)
    }

    return (
        <div className={classes.main}>
            <Typography variant={'body2'}>Rankings</Typography>
            <Card className={classes.card} variant={'outlined'}>
                {!loading && (
                    <List height={'87%'} style={{ minHeight: '89%' }} width={'99%'}>
                        {rankings.map((item, index) => (
                            <ListItemButton
                                key={index}
                                onClick={() => {
                                    onTap(item)
                                }}
                            >
                                <ListItemAvatar>
                                    <Star />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={item.title}
                                    secondary={
                                        'Spent (' +
                                        item.points.toLocaleString(undefined, {
                                            style: 'currency',
                                            currency: 'TRY',
                                        }) +
                                        ') Payback (' +
                                        item.payback.toLocaleString(undefined, {
                                            style: 'currency',
                                            currency: 'TRY',
                                        }) +
                                        ')'
                                    }
                                />
                                <Divider variant={'inset'} />
                            </ListItemButton>
                        ))}
                    </List>
                )}
                <Divider variant={'fullWidth'} />
                {!loading && data != null && (
                    <div style={{ margin: '1em' }}>
                        <Typography variant={'h6'}>
                            Total Payback -{' '}
                            {data.percentage.toLocaleString(undefined, {
                                style: 'currency',
                                currency: 'TRY',
                            })}
                        </Typography>
                    </div>
                )}
                {loading && (
                    <div style={{ textAlign: 'center', height: '100%' }}>
                        <CircularProgress color={'primary'} />
                    </div>
                )}
            </Card>
        </div>
    )
}
