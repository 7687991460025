import React, { type FC, type ReactElement, useContext, useReducer } from 'react'

export interface GlobalState {
    loading: boolean
    displays?: Record<string, BreadItem>
}

export interface BreadItem {
    title?: string
    icon?: FC<any>
}

interface GlobalStateStore {
    state: StateUpdate
    dispatch?: React.Dispatch<StateUpdate>
}

interface GlobalStateProviderProps {
    children: ReactElement | ReactElement[]
}

const GlobalStateContext = React.createContext<GlobalStateStore>({
    state: { loading: false },
})

export const GlobalStateProvider = ({ children }: GlobalStateProviderProps) => {
    const [state, dispatch] = useReducer(reducer, { loading: false })

    return <GlobalStateContext.Provider value={{ state, dispatch }}>{children}</GlobalStateContext.Provider>
}

type StateUpdate = Partial<GlobalState>

const reducer = (state: GlobalState, update: StateUpdate) => {
    return { ...state, ...update }
}

export const useGlobalState = () => useContext(GlobalStateContext)
