import React from 'react'
import { Avatar, Dialog, DialogContent } from '@mui/material'
import RankingHistoryTableView from './ranking-history-table-view'
import { Button, useRecordContext } from 'react-admin'
import Iconify from '../../../components/iconify'
import { useBoolean } from '../../../hooks/use-boolean'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { fDateTime } from '../../../utils/format-time'

export default function RankingHistoryView() {
    const record = useRecordContext()
    const open = useBoolean()
    if (record === undefined) {
        return <></>
    }
    return (
        <>
            <Button label={'Ranks'} onClick={open.onTrue} startIcon={<Iconify icon={'solar:ranking-bold-duotone'} />} />
            <Dialog
                onClose={open.onFalse}
                fullScreen
                fullWidth
                PaperProps={{
                    sx: {
                        maxWidth: 'calc(100% - 48px)',
                        minHeight: '90%',
                        height: '90%',
                        borderRadius: 1.5,
                    },
                }}
                sx={{
                    marginLeft: '24px',
                    maxWidth: 'calc(100% - 48px)',
                }}
                open={open.value}
                keepMounted={false}
            >
                <DialogContent>
                    <AppBar position={'sticky'} color={'inherit'} variant={'elevation'}>
                        <Toolbar disableGutters>
                            <Stack sx={{ ml: 2, flex: 1 }} spacing={1} direction={'row'} alignItems={'center'}>
                                <Avatar sx={{ width: 34, height: 34 }} color={'primary'}>
                                    <Iconify icon={'solar:ranking-bold-duotone'} />
                                </Avatar>
                                <Stack direction={'column'}>
                                    <Typography variant="h6" component="div">
                                        {fDateTime(record.startedAt)} - {fDateTime(record.endsAt)}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <IconButton edge="start" color="inherit" onClick={open.onFalse} aria-label="close">
                                <Iconify icon={'material-symbols:close'} />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    {open && record && <RankingHistoryTableView challenge={record.id} />}
                </DialogContent>
            </Dialog>
        </>
    )
}
