import React from 'react'
import Icon from '@mui/icons-material/Whatshot'
import {
    AutocompleteArrayInput,
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    Filter,
    ImageField,
    maxLength,
    ReferenceArrayInput,
    required,
    Show,
    ShowButton,
    SimpleShowLayout,
    TabbedForm,
    TextField,
    TextInput,
} from 'react-admin'
import ImageUpload from '../common/ImageUpload'
import { ListPagination } from '../common/Pagination'
import { SearchInput } from '../common/SearchInput'
import { RaFixedList } from '../common/ra-fixed-list'

export const CampaignIcon = Icon

export const CampaignCreate = (props) => (
    <Create {...props}>
        <CreateOrEdit />
    </Create>
)

export const CampaignEdit = (props) => (
    <Edit {...props}>
        <CreateOrEdit />
    </Edit>
)

const CampaignFilters = (props) => (
    <Filter {...props}>
        <SearchInput source={'name.english'} alwaysOn label={'English Name'} />
        <SearchInput source={'name.turkish'} alwaysOn label={'Turkish Name'} />
        <BooleanInput source={'slider'} />
        <BooleanInput source={'home'} />
    </Filter>
)
export const CampaignList = () => (
    <RaFixedList
        sort={{ field: 'time', order: 'DESC' }}
        pagination={<ListPagination />}
        perPage={50}
        filters={<CampaignFilters />}
    >
        <CampaignGrid />
    </RaFixedList>
)

export const CampaignGrid = () => (
    <Datagrid bulkActionButtons={false}>
        <TextField source={'name.turkish'} />
        <BooleanField source={'home'} />
        <BooleanField source={'slider'} />
        <ShowButton />
        <EditButton />
        <DeleteWithConfirmButton />
    </Datagrid>
)
export const CampaignShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source={'name.english'} />
            <TextField source={'name.turkish'} />
            <TextField source={'description.english'} />
            <TextField source={'description.turkish'} />
            <ImageField source={'image'} />
        </SimpleShowLayout>
    </Show>
)

const CreateOrEdit = () => (
    <TabbedForm syncWithLocation={false} redirect={'list'}>
        <TabbedForm.Tab label={'resources.campaigns.names'}>
            <TextInput source={'name.english'} validate={[required(), maxLength(255)]} />
            <TextInput source={'name.turkish'} validate={[required(), maxLength(255)]} />

            <TextInput source={'description.english'} fullWidth validate={[required(), maxLength(1000000)]} />
            <TextInput source={'description.turkish'} fullWidth validate={[maxLength(1000000)]} />
        </TabbedForm.Tab>
        <TabbedForm.Tab label={'resources.products.name'}>
            <ReferenceArrayInput source={'products'} reference={'products'} name={'products'}>
                <AutocompleteArrayInput
                    filterToQuery={(text) => ({ name: { turkish: text } })}
                    source={'id'}
                    optionText={'name.turkish'}
                />
            </ReferenceArrayInput>
            <BooleanInput source={'home'} />
            <BooleanInput source={'slider'} />
            <BooleanInput source={'sendNotification'} />
        </TabbedForm.Tab>
        <TabbedForm.Tab label={'resources.campaigns.fields.image'}>
            <ImageUpload source={'image'} required={true} />
        </TabbedForm.Tab>
    </TabbedForm>
)
export const CampaignTranslations = {
    en: {
        campaigns: {
            name: 'Campaigns',
            general: 'General',
            names: 'Name',
            fields: {
                name: {
                    english: 'English Title',
                    turkish: 'Turkish Title',
                },
                image: 'Image',
                description: {
                    english: 'Description',
                    turkish: 'Turkish Description',
                },
                products: 'Products',
                home: 'Should Appear in Home?',
                slider: 'Should appear in Slider?',
            },
        },
    },
    tr: {
        campaigns: {
            name: 'Kampanyalar',
            general: 'Genel',
            names: 'İsim',
            fields: {
                name: {
                    english: 'English başlık',
                    turkish: 'Turkce başlık',
                },
                image: 'resim',
                description: {
                    english: 'Açıklama ingilizce',
                    turkish: 'Açıklama türkçe',
                },
                products: 'Ürünler',
                home: 'Ana sayfada görünsün mü?',
                slider: 'Slider da görünsün mü?',
            },
        },
    },
}
