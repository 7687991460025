import React, { useRef } from 'react'
import { MapContainer, Marker, TileLayer, useMapEvents } from 'react-leaflet'
import { useController } from 'react-hook-form'
import { useFormControl } from '@mui/material'
import { useRecordContext } from 'react-admin'

export function MapInput({ required, source }) {
    const ref = useRef()
    const control = useFormControl()
    const { field } = useController({
        source,
        control,
        name: source,
        rules: { required: required },
    })

    return (
        <MapContainer
            center={
                field.value || {
                    lat: 41.015137,
                    lng: 28.97953,
                }
            }
            zoom={23}
            ref={ref}
            length={4}
            style={{ height: '400px', width: '100%' }}
        >
            <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <LocationMarker value={field.value} onChange={field.onChange} />
        </MapContainer>
    )
}

function LocationMarker({ onChange, value }) {
    const map = useMapEvents({
        click(e) {
            console.log(e)
            onChange(e.latlng)
        },
    })
    if (value) {
        return <Marker position={value} />
    }
    return <></>
}

export default function MapView({ source }) {
    const record = useRecordContext()
    const value = record[source]
    const marker = value ? <Marker position={value} /> : null
    return (
        <MapContainer center={value} zoom={23} length={4} style={{ height: '400px' }}>
            <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {marker}
        </MapContainer>
    )
}
