import React from 'react'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { useController } from 'react-hook-form'
import { startOfYesterday } from 'date-fns'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useFormControl } from '@mui/material'
import { useTranslate } from 'react-admin'
import trLocale from 'date-fns/locale/tr'

export const DatePickerInput = ({ source, label, required = false, min = undefined, disabled = false }) => {
    const control = useFormControl()
    const translate = useTranslate()
    const { field } = useController({
        source,
        control,
        name: source,
        rules: { required: required },
    })
    if (!field.value) {
        field.onChange(startOfYesterday().getTime())
    } else if (typeof field.value === 'string') {
        const date = new Date(field.value)
        field.onChange(date.getTime())
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={trLocale}>
            <DesktopDatePicker
                disabled={disabled}
                value={field.value ? new Date(field.value) : startOfYesterday().getTime()}
                label={translate(label)}
                format={'dd.MM.yyyy'}
                minDate={min}
                slotProps={{ textField: { size: 'small' } }}
                onChange={(date) => {
                    field.onChange(date.getTime())
                }}
            />
        </LocalizationProvider>
    )
}
