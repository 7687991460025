import React, { useCallback, useEffect, useState } from 'react'
import Collapse from '@mui/material/Collapse'
import { NavConfigProps, NavListProps } from '../types'
import NavItem from './nav-item'
import { useActiveLink, usePathname } from '../../../routes/hooks'

type NavListRootProps = {
    data: NavListProps
    depth: number
    hasChild: boolean
    config: NavConfigProps
}

export default function NavList({ data, depth, hasChild, config }: NavListRootProps) {
    const pathname = usePathname()

    const active = useActiveLink(data.path, hasChild)

    const externalLink = data.path.includes('http')

    const [open, setOpen] = useState(active)

    useEffect(() => {
        if (!active) {
            handleClose()
        }
    }, [pathname])

    const handleToggle = useCallback(() => {
        setOpen((prev) => !prev)
    }, [])

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    return (
        <>
            <NavItem
                item={data}
                depth={depth}
                open={open}
                active={active}
                externalLink={externalLink}
                onClick={handleToggle}
                config={config}
            />

            {hasChild && (
                <Collapse in={open} unmountOnExit>
                    <NavSubList data={data.children} depth={depth} config={config} />
                </Collapse>
            )}
        </>
    )
}

// ----------------------------------------------------------------------

type NavListSubProps = {
    data: NavListProps[]
    depth: number
    config: NavConfigProps
}

function NavSubList({ data, depth, config }: NavListSubProps) {
    return (
        <>
            {data.map((list) => (
                <NavList
                    key={list.title + list.path}
                    data={list}
                    depth={depth + 1}
                    hasChild={!!list.children}
                    config={config}
                />
            ))}
        </>
    )
}
