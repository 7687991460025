import React, { useState } from 'react'
import { Button, Dialog, DialogActions } from '@mui/material'
import SendIcon from '@mui/icons-material/Refresh'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { fetchJson } from '../rest/fetch'
import { SERVER_IP } from '../index'
import CircularProgress from '@mui/material/CircularProgress'
import { useTranslate } from 'react-admin'

export default function ResetDeviceView({ onResponse, open, record }) {
    const translate = useTranslate()
    const [loading, setLoading] = useState(false)
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
    }

    const handleSend = () => {
        if (record === undefined) {
            return
        }
        setLoading(true)

        fetchJson(`${SERVER_IP}/users/resetDevice/${record.id}`, { method: 'PUT' }).then(
            () => {
                setLoading(false)
                onResponse(translate('users.resetDevice.done'), false)
            },
            () => {
                setLoading(false)
                onResponse(translate('users.resetDevice.failed'), false)
            }
        )
    }

    let title = translate('users.resetDevice.resetTitle')
    if (record !== undefined) {
        title = title + record.name
    }
    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{translate('users.resetDevice.resetMessage')}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={loading}
                    variant={'text'}
                    color={'primary'}
                    onClick={() => {
                        onResponse(null, false)
                    }}
                >
                    {translate('users.resetDevice.cancel')}
                </Button>
                <Button disabled={loading} variant={'text'} color={'primary'} onClick={handleSend}>
                    {loading && <CircularProgress size={25} thickness={2} />}
                    <SendIcon />
                    {translate('users.resetDevice.reset')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export const UserResetTranslations = {
    en: {
        users: {
            resetDevice: {
                resetTitle: 'Reset device for ',
                resetMessage: 'This will allow user login from new device. Are you sure to reset?',
                cancel: 'Cancel',
                body: 'Body',
                subject: 'Subject',
                done: 'Device reset successful',
                failed: 'Failed to reset device.',
                reset: 'Reset Device',
            },
        },
    },
    tr: {
        users: {
            resetDevice: {
                resetTitle: 'Reset device for ',
                resetMessage: 'This will allow user login from new device. Are you sure to reset?',
                cancel: 'Cancel',
                body: 'Body',
                subject: 'Subject',
                done: 'Device reset successful',
                failed: 'Failed to reset device.',
                reset: 'Reset Device',
            },
        },
    },
}
