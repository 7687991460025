import {
    Create,
    Datagrid,
    DateField,
    Edit,
    EditButton,
    NumberField,
    TextField,
    useRecordContext,
    WrapperField,
} from 'react-admin'
import React from 'react'
import PauseRankingButton from './pause-ranking-view'
import EditCreateRankingView from './edit-create-ranking-view'
import TotalPaybackField from './total-payback-field'
import RankingHistoryView from './history/ranking-history-view'
import { RaFixedList } from '../../common/ra-fixed-list'

export const ListRankings = () => {
    return (
        <RaFixedList sort={{ field: 'endsAt', order: 'DESC' }} exporter={false}>
            <Datagrid bulkActionButtons={false}>
                <DateField showTime locales={'tr'} source={'startedAt'} />
                <DateField showTime locales={'tr'} source={'endsAt'} />
                <NumberField source={'expenses'} />
                <TextField source={'status'} />
                <WrapperField label={'Payback'} source={'payback'}>
                    <TotalPaybackField />
                </WrapperField>
                <WrapperField label={'History'} source={'history'}>
                    <RankingHistoryView />
                </WrapperField>
                <PauseRankingButton />
                <AllowedEditButton />
            </Datagrid>
        </RaFixedList>
    )
}

const AllowedEditButton = () => {
    const record = useRecordContext()
    if (!record || record.status === 'PASSED') {
        return <></>
    }
    return <EditButton />
}
export const CreateRanking = () => {
    return (
        <Create>
            <EditCreateRankingView edit={false} />
        </Create>
    )
}

export const EditRanking = () => {
    return (
        <Edit>
            <EditCreateRankingView edit={true} />
        </Edit>
    )
}
