import React from 'react'
import { Datagrid, TextField, useRecordContext, useResourceContext } from 'react-admin'
import { TimeField } from '../common/TimeField'
import Button from '@mui/material/Button'
import ReceiptIcon from '@mui/icons-material/Receipt'
import { SERVER_IP } from '../index'
import { Filters } from '../report/ProductSaleReport'
import { RaFixedList } from '../common/ra-fixed-list'

export const SaleList = () => (
    <RaFixedList sort={{ field: 'time', order: 'DESC' }} exporter={false} filters={<Filters />}>
        <Datagrid bulkActionButtons={false}>
            <TextField source={'id'} />
            <TimeField source={'time'} />
            <ReceiptButton />
        </Datagrid>
    </RaFixedList>
)

const ReceiptButton = () => {
    const resource = useResourceContext()
    const record = useRecordContext()
    const openReceipt = () => {
        if (record) {
            let token = localStorage.getItem('token')
            window.open(`${SERVER_IP}/${resource}/receipt/${record.id}?token=${token}`, '_blank')
        }
    }
    return (
        <Button variant={'text'} color={'primary'} onClick={() => openReceipt()} startIcon={<ReceiptIcon />}>
            Receipt
        </Button>
    )
}

export const SaleTranslations = {
    en: {
        sales: {
            name: 'Shop Ventes',
            fields: {
                id: '#',
                product: 'Product search with barcode',
                quantity: 'Quantity',
                itemPrice: 'Item Price',
                price: 'Total Price',
                barcode: 'Barcode',
            },
        },
        depoSales: {
            name: 'Depot Ventes',
            fields: {
                id: '#',
                product: 'Product search with barcode',
                quantity: 'Quantity',
                itemPrice: 'Item Price',
                price: 'Total Price',
                barcode: 'Barcode',
            },
        },
    },
    tr: {
        sales: {
            name: 'Mağaza satış',
            fields: {
                id: '#',
                product: 'Barkod ile ürün arama',
                quantity: 'Adet',
                itemPrice: 'ürün fiyatı',
                price: 'Taplam ücret',
                barcode: 'Barkod',
            },
        },
        depoSales: {
            name: 'Depo satış',
            fields: {
                id: '#',
                product: 'Barkod ile ürün arama',
                quantity: 'Adet',
                itemPrice: 'Ürün Fiyatı',
                price: 'Toplam Ücret',
                barcode: 'Barkod',
            },
        },
    },
}
