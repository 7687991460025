import { useRecordContext } from 'react-admin'
import React, { useEffect, useState } from 'react'
import { fetchJson } from '../../rest/fetch'
import { SERVER_IP } from '../../index'
import { useBoolean } from '../../hooks/use-boolean'
import { CircularProgress } from '@mui/material'
import Typography from '@mui/material/Typography'

export default function TotalPaybackField() {
    const record = useRecordContext()
    const loading = useBoolean()
    const [payback, setPayback] = useState(0)
    useEffect(() => {
        loading.onTrue()
        fetchJson(`${SERVER_IP}/rankings/getPayback/${record.id}`)
            .then(({ json }) => {
                setPayback(json.payback)
            })
            .catch(() => {})
            .finally(loading.onFalse)
    }, [record])
    if (loading.value) {
        return <CircularProgress size={24} color={'primary'} />
    } else {
        return <Typography variant={'body1'}>{Number(payback).toFixed(2)} TL</Typography>
    }
}
