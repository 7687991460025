import React from 'react'
import { Datagrid, TextField } from 'react-admin'
import { TimeField } from '../common/TimeField'
import Button from '@mui/material/Button'
import ReceiptIcon from '@mui/icons-material/Receipt'
import { SERVER_IP } from '../index'
import { Filters } from '../report/ProductSaleReport'
import { RaFixedList } from '../common/ra-fixed-list'

export const RefundList = (props) => (
    <RaFixedList {...props} sort={{ field: 'time', order: 'DESC' }} exporter={false} filters={<Filters />}>
        <Datagrid bulkActionButtons={false}>
            <TextField source={'id'} />
            <TimeField source={'time'} />
            <ReceiptButton {...props} />
        </Datagrid>
    </RaFixedList>
)

const ReceiptButton = ({ resource, record }) => {
    const openReceipt = () => {
        if (record) {
            let token = localStorage.getItem('token')
            window.open(`${SERVER_IP}/${resource}/receipt/${record.id}?token=${token}`, '_blank')
        }
    }
    return (
        <Button variant={'text'} color={'primary'} onClick={() => openReceipt()} startIcon={<ReceiptIcon />}>
            Receipt
        </Button>
    )
}

export const RefundTranslations = {
    en: {
        refunds: {
            name: 'Shop Retours',
            fields: {
                id: '#',
                product: 'Product search with barcode',
                quantity: 'Quantity',
                itemPrice: 'Item Price',
                price: 'Total Price',
                barcode: 'Barcode',
            },
        },
        depoRefunds: {
            name: 'Depot Retours',
            fields: {
                id: '#',
                product: 'Product search with barcode',
                quantity: 'Quantity',
                itemPrice: 'Item Price',
                price: 'Total Price',
                barcode: 'Barcode',
            },
        },
    },
    tr: {
        refunds: {
            name: 'Magaza iade',
            fields: {
                id: '#',
                product: 'Barkod ile ürün arama',
                quantity: 'Adet',
                itemPrice: 'Ürün fiyatı',
                price: 'Toplam Ücret',
                barcode: 'Barkod',
            },
        },
        depoRefunds: {
            name: 'Depo İade',
            fields: {
                id: '#',
                product: 'Barkod ile ürün arama',
                quantity: 'Adet',
                itemPrice: 'Ürün Fiyatı',
                price: 'Totlam Ücret',
                barcode: 'Barkod',
            },
        },
    },
}
