import React from 'react'
import Box, { BoxProps } from '@mui/material/Box'
import { useResponsive } from 'src/hooks/use-responsive'
import { useSettingsContext } from 'src/components/settings'
import { HEADER, NAV } from '../config-layout'

const SPACING = 8

export default function Main({ children, sx, ...other }: BoxProps) {
    const settings = useSettingsContext()

    const lgUp = useResponsive('up', 'lg')

    const isNavHorizontal = settings.themeLayout === 'horizontal'

    const isNavMini = settings.themeLayout === 'mini'

    if (isNavHorizontal) {
        return (
            <Box
                component="main"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    top: `${HEADER.H_MOBILE}px`,
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    ...(lgUp && {
                        top: `${HEADER.H_MOBILE * 2}px`,
                    }),
                }}
            >
                {children}
            </Box>
        )
    }

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                display: 'flex',
                position: 'absolute',
                top: `${HEADER.H_MOBILE + SPACING}px`,
                bottom: `1px`,
                right: '10px',
                pl: 2,
                flexDirection: 'column',
                ...(lgUp && {
                    top: `${HEADER.H_DESKTOP + SPACING}px`,
                    left: `${NAV.W_VERTICAL}px`,
                    width: `calc(100% - ${NAV.W_VERTICAL + 10}px)`,
                    ...(isNavMini && {
                        width: `calc(100% - ${NAV.W_MINI + 10}px)`,
                        left: `${NAV.W_MINI}px`,
                    }),
                }),
                ...sx,
            }}
            {...other}
        >
            {children}
        </Box>
    )
}
