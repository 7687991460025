import React from 'react'
import {
    CloneButton,
    Create,
    Datagrid,
    DeleteButton,
    ImageField,
    maxLength,
    required,
    Show,
    ShowButton,
    SimpleShowLayout,
    TabbedForm,
    TextField,
    TextInput,
} from 'react-admin'
import { TimeField } from '../common/TimeField'
import ImageUpload from '../common/ImageUpload'
import { ListPagination } from '../common/Pagination'
import { RaFixedList } from '../common/ra-fixed-list'

export const NotificationsList = (props) => (
    <RaFixedList {...props} sort={{ field: 'time', order: 'DESC' }} perPage={50} pagination={<ListPagination />}>
        <Datagrid>
            <TextField source={'id'} />
            <TextField source={'subject.turkish'} />
            <TextField source={'body.turkish'} />
            <TimeField source={'time'} />
            <CloneButton />
            <ShowButton />
            <DeleteButton />
        </Datagrid>
    </RaFixedList>
)

export const NotificationCreate = (props) => (
    <Create {...props}>
        <TabbedForm syncWithLocation={false}>
            <TabbedForm.Tab label={'Text'}>
                <TextInput source={'subject.english'} validate={[required(), maxLength(255)]} />

                <TextInput source={'subject.turkish'} validate={[maxLength(255)]} />

                <TextInput source={'body.english'} fullWidth multiline validate={[required(), maxLength(1000000)]} />
                <TextInput source={'body.turkish'} fullWidth multiline validate={[maxLength(1000000)]} />
            </TabbedForm.Tab>
            <TabbedForm.Tab label={'Image'}>
                <ImageUpload source={'image'} />
            </TabbedForm.Tab>
        </TabbedForm>
    </Create>
)

export const NotificationShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source={'subject.english'} />
            <TextField source={'subject.turkish'} />
            <TextField source={'body.english'} />
            <TextField source={'body.turkish'} />
            <ImageField source={'image'} />
        </SimpleShowLayout>
    </Show>
)

export const NotificationTranslations = {
    en: {
        notifications: {
            name: 'Notifications',
            fields: {
                id: '#',
                subject: {
                    english: 'Subject',
                    turkish: 'Turkish subject',
                },
                body: {
                    english: 'Body',
                    turkish: 'Body in Turkish',
                },
            },
        },
    },
    tr: {
        notifications: {
            name: 'Bildirimler',
            fields: {
                id: '#',
                subject: {
                    english: 'İngilizce Başlık',
                    turkish: 'Turkce başlık',
                },
                body: {
                    english: 'Bildirim mesajı ingilizce',
                    turkish: 'Bildirim mesajı turkce',
                },
            },
        },
    },
}
