import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Drawer from '@mui/material/Drawer'
import { useResponsive } from 'src/hooks/use-responsive'
import Scrollbar from 'src/components/scrollbar'
import { usePathname } from 'src/routes/hooks'
import { NavSectionVertical } from 'src/components/nav-section'
import { NAV } from '../config-layout'
import { useNavData } from './config-navigation'
import { NavToggleButton } from '../_common'
import Logo from 'src/components/logo'

type Props = {
    openNav: boolean
    onCloseNav: VoidFunction
}

export default function NavVertical({ openNav, onCloseNav }: Props) {
    const pathname = usePathname()

    const lgUp = useResponsive('up', 'lg')

    const navData = useNavData()

    useEffect(() => {
        if (openNav) {
            onCloseNav()
        }
    }, [pathname])

    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                '& .simplebar-content': {
                    height: 1,
                    display: 'flex',
                    flexDirection: 'column',
                },
            }}
        >
            <Logo sx={{ mt: 3, ml: 4, mb: 1 }} />

            <NavSectionVertical data={navData} />

            <Box sx={{ flexGrow: 1 }} />
        </Scrollbar>
    )

    return (
        <Box
            component="nav"
            sx={{
                flexShrink: { lg: 0 },
                width: { lg: NAV.W_VERTICAL },
            }}
        >
            <NavToggleButton />

            {lgUp ? (
                <Stack
                    sx={{
                        height: 1,
                        position: 'fixed',
                        width: NAV.W_VERTICAL,
                        background: (theme) => `${theme.palette.background.default}`,
                        borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
                    }}
                >
                    {renderContent}
                </Stack>
            ) : (
                <Drawer
                    open={openNav}
                    onClose={onCloseNav}
                    PaperProps={{
                        sx: {
                            width: NAV.W_VERTICAL,
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </Box>
    )
}
