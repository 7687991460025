import React, { useState } from 'react'
import SendIcon from '@mui/icons-material/LocalShipping'
import { Button, DialogActions } from '@mui/material'
import DeliverOrderView from './DeliverOrderView'
import { useRecordContext, useTranslate } from 'react-admin'
import { useSnackbar } from 'notistack'

export default function UserShowActions() {
    const [state, setState] = useState({})
    const translate = useTranslate()
    const data = useRecordContext()
    const { enqueueSnackbar } = useSnackbar()
    const { open } = state
    return (
        <DialogActions>
            {data !== undefined && (
                <Button
                    variant={'text'}
                    color={'primary'}
                    disabled={data.status !== undefined && (data.status === 'DELIVERED' || data.status === 'CANCELLED')}
                    onClick={() => setState({ open: true })}
                >
                    <SendIcon /> {translate('orders.deliverOrder.title')}
                </Button>
            )}
            <DeliverOrderView
                open={open}
                onResponse={(message, open) => {
                    if (message) {
                        enqueueSnackbar(message)
                    }
                    setState({ open: open })
                }}
                record={data}
            />
        </DialogActions>
    )
}
