import { Button, useRecordContext, useRefresh } from 'react-admin'
import { useBoolean } from '../../hooks/use-boolean'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { DialogContentText } from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import MuiButton from '@mui/material/Button'
import React from 'react'
import Iconify from '../../components/iconify'
import { fetchJson } from '../../rest/fetch'
import { SERVER_IP } from '../../index'
import { useSnackbar } from 'notistack'

export default function PauseRankingButton() {
    const record = useRecordContext()
    const open = useBoolean()
    const { enqueueSnackbar } = useSnackbar()
    const refresh = useRefresh()
    const paused = record.status === 'PAUSED'
    const toggleRankingPause = () => {
        open.onFalse()
        const path = paused ? '/resume' : '/pause'
        fetchJson(`${SERVER_IP}/rankings${path}`, { method: 'PATCH' })
            .then(() => {
                enqueueSnackbar('Challenge status updated.')
                refresh()
            })
            .catch(() => {
                enqueueSnackbar('Failed to update challenge status.', { variant: 'error' })
            })
    }
    const passed = record.status === 'PASSED'
    if (passed) {
        return <></>
    }
    return (
        <>
            <Button
                label={paused ? 'Resume' : 'Pause'}
                onClick={open.onTrue}
                startIcon={<Iconify icon={paused ? 'solar:play-bold-duotone' : 'solar:pause-bold-duotone'} />}
            />
            <Dialog open={open.value}>
                <DialogTitle>Confirm</DialogTitle>
                <DialogContent>
                    <DialogContentText>Do you really want to update the status of challenge?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MuiButton color={'error'} onClick={toggleRankingPause}>
                        Yes
                    </MuiButton>
                    <MuiButton onClick={open.onFalse}>No</MuiButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
