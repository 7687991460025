import React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { useRecordContext, useTranslate } from 'react-admin'

export default function Basket() {
    const translate = useTranslate()
    const record = useRecordContext()
    if (record === undefined) {
        return <></>
    }
    return (
        <Paper sx={{ m: 1 }} elevation={2}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{translate('resources.orders.fields.basket.id')}</TableCell>
                        <TableCell>{translate('resources.orders.fields.basket.name')}</TableCell>
                        <TableCell sx={{ textAlign: 'right' }}>
                            {translate('resources.orders.fields.basket.unitPrice')}
                        </TableCell>
                        <TableCell sx={{ textAlign: 'right' }}>
                            {translate('resources.orders.fields.basket.quantity')}
                        </TableCell>
                        <TableCell sx={{ textAlign: 'right' }}>
                            {translate('resources.orders.fields.basket.total')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {record.items.map((item) => (
                        <TableRow key={item.product.id}>
                            <TableCell>{item.product.id}</TableCell>
                            <TableCell>{item.product.name.english}</TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>
                                {item.usedPrice.toLocaleString(undefined, {
                                    style: 'currency',
                                    currency: 'TRY',
                                })}
                            </TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>{item.quantity}</TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>
                                {(item.usedPrice * item.quantity).toLocaleString(undefined, {
                                    style: 'currency',
                                    currency: 'TRY',
                                })}
                            </TableCell>
                        </TableRow>
                    ))}

                    <TableRow>
                        <TableCell colSpan={3} />
                        <TableCell sx={{ fontWeight: 'bold' }}>
                            {translate('resources.orders.fields.basket.total')}
                        </TableCell>
                        <TableCell sx={{ textAlign: 'right', fontWeight: 'bold' }}>
                            {record.total.toLocaleString(undefined, {
                                style: 'currency',
                                currency: 'TRY',
                            })}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Paper>
    )
}
