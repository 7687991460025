export const RankingTranslations = {
    en: {
        rankings: {
            name: 'Rankings',
        },
    },
    tr: {
        rankings: {
            name: 'Rankings',
        },
    },
}
