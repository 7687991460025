import React from 'react'
import Icon from '@mui/icons-material/EmojiNature'
import {
    ArrayField,
    AutocompleteInput,
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    Filter,
    FormDataConsumer,
    FunctionField,
    ImageField,
    maxLength,
    minValue,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    required,
    SelectInput,
    Show,
    ShowButton,
    SimpleShowLayout,
    SingleFieldList,
    TabbedForm,
    TextField,
    TextInput,
    usePermissions,
} from 'react-admin'
import ImageUpload from '../common/ImageUpload'
import { ProductsListBulkActions } from './ProductsListBulkActions'
import { ListPagination } from '../common/Pagination'
import { TimeField } from '../common/TimeField'
import { DatePickerInput } from '../common/DatePickerInput'
import { StringArrayInput } from '../common/StringArrayInput'
import { Chip, InputAdornment } from '@mui/material'
import FilledTextField from '@mui/material/TextField'
import { ProductListActions } from './ProductListActions'
import { SearchInput } from '../common/SearchInput'
import Grid from '@mui/material/Grid'
import { RaFixedList } from '../common/ra-fixed-list'

export const ProductIcon = Icon

export const ProductCreate = (props) => (
    <Create {...props} transform={transformInput}>
        <CreateOrEdit />
    </Create>
)
const transformInput = (data) => {
    const { secondCategory } = data
    if (secondCategory && secondCategory.id === null) {
        data.secondCategory = null
    }
    return data
}

export const ProductEdit = (props) => (
    <Edit {...props} transform={transformInput}>
        <CreateOrEdit />
    </Edit>
)

const ProductFilters = (props) => (
    <Filter {...props}>
        <SearchInput source={'name.english'} alwaysOn label={'English Name'} />
        <SearchInput source={'name.turkish'} alwaysOn label={'Turkish Input'} />
        <SearchInput source={'barcode'} alwaysOn label={'Barcode'} />
        <NumberInput source={'id'} alwaysOn />
        <ReferenceInput source={'category.id'} perPage={1000} reference={'categories'} validate={required()}>
            <SelectInput optionText={'name.turkish'} />
        </ReferenceInput>
        <NumberInput source={'stock'} />
        <BooleanInput source={'enableStockManagement'} />
    </Filter>
)
export const ProductList = () => {
    const { permissions } = usePermissions()
    return (
        <RaFixedList
            pagination={<ListPagination />}
            perPage={50}
            filterDefaultValues={{}}
            sort={{ field: 'id', order: 'DESC' }}
            actions={permissions === 'ROLE_ADMIN' && <ProductListActions />}
            filters={<ProductFilters />}
        >
            <ProductGrid permissions={permissions} />
        </RaFixedList>
    )
}

function calculateMargin(record) {
    let difference = record.price - record.purchasePrice
    return ((difference / record.purchasePrice) * 100).toFixed(0)
}

export const ProductGrid = ({ permissions }) => (
    <Datagrid bulkActionButtons={permissions === 'ROLE_ADMIN' && <ProductsListBulkActions />}>
        <TextField source={'name.turkish'} />
        <TextField source={'barcode'} />
        <ReferenceField reference={'categories'} source={'categoryId'}>
            <TextField source={'name.turkish'} />
        </ReferenceField>
        <ReferenceField reference={'categories'} source={'secondCategoryId'}>
            <TextField source={'name.turkish'} />
        </ReferenceField>
        {(permissions === 'ROLE_ADMIN' || permissions === 'ROLE_SALE_PERSON') && <TextField source={'purchasePrice'} />}
        <TextField source={'price'} />
        <TextField source={'newPrice'} />
        {permissions === 'ROLE_ADMIN' && (
            <FunctionField
                sortBy={'margin'}
                label={'Margin'}
                render={(record) => record !== undefined && `${Number(record.margin).toFixed(0)}%`}
            />
        )}
        <TextField source={'stock'} />
        <TextField source={'amount'} />
        <ReferenceField reference={'productUnits'} source={'unitId'}>
            <TextField source={'name'} />
        </ReferenceField>
        <FunctionField
            source={'tax'}
            render={(record) => (record === undefined || record.tax == null ? '0%' : `${record.tax}%`)}
        />
        <ShowButton />
        {permissions === 'ROLE_ADMIN' && <EditButton />}
        {permissions === 'ROLE_ADMIN' && <DeleteWithConfirmButton />}
    </Datagrid>
)
export const ProductShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source={'barcode'} />
            <TextField source={'name.english'} />
            <TextField source={'name.turkish'} />

            <TextField source={'description.english'} />
            <TextField source={'description.turkish'} />

            <ReferenceField reference={'categories'} source={'categoryId'}>
                <TextField source={'name.turkish'} />
            </ReferenceField>
            <ReferenceField reference={'categories'} source={'secondCategoryId'}>
                <TextField source={'name.turkish'} />
            </ReferenceField>
            <TextField source={'purchasePrice'} />
            <TextField source={'price'} />
            <TextField source={'newPrice'} />
            <TextField source={'maximumOrderLimit'} />
            <TextField source={'stock'} />
            <TextField source={'lowStockLimit'} />
            <TimeField source={'expirationDate'} />
            <ImageField source={'image'} />
            <BooleanField source={'enableStockManagement'} />
            <TextField source={'tax'} />
            <FunctionField
                source={'tax'}
                render={(record) => (record === undefined || record.tax == null ? '0%' : `${record.tax}%`)}
            />
            <ArrayField source="extraBarcodes">
                <SingleFieldList>
                    <FunctionField render={(record) => <Chip label={record} />} />
                </SingleFieldList>
            </ArrayField>
            <ReferenceField reference={'productUnits'} source={'unitId'}>
                <TextField source={'name'} />
            </ReferenceField>
            <TextField source={'amount'} />
        </SimpleShowLayout>
    </Show>
)

const CreateOrEdit = () => {
    return (
        <TabbedForm redirect={'list'} syncWithLocation={false} sanitizeEmptyValues>
            <TabbedForm.Tab label={'resources.products.names'}>
                <Grid container columnSpacing={2} rowGap={2}>
                    <Grid item xs={6}>
                        <TextInput fullWidth source={'barcode'} validate={[required(), maxLength(255)]} />
                    </Grid>
                    <Grid item xs={6}>
                        <StringArrayInput name={'extraBarcodes'} label={'Extra Barcodes'} source={'extraBarcodes'} />{' '}
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput source={'name.english'} fullWidth validate={[required(), maxLength(255)]} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput source={'name.turkish'} fullWidth validate={[required(), maxLength(255)]} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            source={'description.english'}
                            fullWidth
                            validate={[required(), maxLength(1000000)]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            source={'description.turkish'}
                            fullWidth
                            validate={[required(), maxLength(1000000)]}
                        />
                    </Grid>
                </Grid>
            </TabbedForm.Tab>
            <TabbedForm.Tab label={'resources.products.general'}>
                <Grid container xs={8} columnGap={2} rowGap={2}>
                    <NumberInput source={'purchasePrice'} validate={[required(), minValue(1)]} />
                    <NumberInput
                        source={'tax'}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                        }}
                        validate={[required(), minValue(0)]}
                    />
                    <NumberInput source={'price'} validate={[required(), minValue(1)]} />
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.price &&
                            formData.purchasePrice && (
                                <FilledTextField
                                    label={'Margin'}
                                    size={'small'}
                                    value={
                                        calculateMargin({
                                            price: formData.price,
                                            purchasePrice: formData.purchasePrice,
                                        }) + '%'
                                    }
                                    disabled
                                />
                            )
                        }
                    </FormDataConsumer>
                    <NumberInput source={'newPrice'} validate={[minValue(1)]} />
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.newPrice &&
                            formData.purchasePrice && (
                                <FilledTextField
                                    label={'Discounted Margin'}
                                    size={'small'}
                                    value={
                                        calculateMargin({
                                            price: formData.newPrice,
                                            purchasePrice: formData.purchasePrice,
                                        }) + '%'
                                    }
                                    disabled
                                />
                            )
                        }
                    </FormDataConsumer>
                    <ReferenceInput name={'unitId'} source={'unitId'} reference={'productUnits'}>
                        <SelectInput optionText={'name'} />
                    </ReferenceInput>
                    <NumberInput source="amount" name={'amount'} />

                    <NumberInput source={'maximumOrderLimit'} validate={[required(), minValue(1)]} />
                    <NumberInput source={'minimumOrderLimit'} validate={[minValue(1)]} />
                    <NumberInput source={'stock'} validate={[required(), minValue(0)]} />
                    <NumberInput source={'lowStockLimit'} />

                    <DatePickerInput
                        name={'expirationDate'}
                        label={'resources.products.fields.expirationDate'}
                        source={'expirationDate'}
                    />
                    <ReferenceInput source={'categoryId'} reference={'categories'} perPage={1000} validate={required()}>
                        <AutocompleteInput
                            filterToQuery={(text) => ({ 'name.turkish': text })}
                            size={'small'}
                            optionText={'name.turkish'}
                        />
                    </ReferenceInput>
                    <ReferenceInput source={'secondCategoryId'} reference={'categories'} allowEmpty perPage={1000}>
                        <AutocompleteInput
                            filterToQuery={(text) => ({ 'name.turkish': text })}
                            size={'small'}
                            optionText={'name.turkish'}
                        />
                    </ReferenceInput>
                    <BooleanInput source={'enableStockManagement'} />
                </Grid>
            </TabbedForm.Tab>
            <TabbedForm.Tab label={'resources.products.fields.image'}>
                <ImageUpload source={'image'} required={true} />
            </TabbedForm.Tab>
        </TabbedForm>
    )
}
export const ProductTranslations = {
    en: {
        products: {
            name: 'Products',
            general: 'General',
            names: 'Name',
            unitTypes: {
                kg: 'KG',
                box: 'Box',
                number: 'Number',
            },
            fields: {
                name: {
                    english: 'English Title',
                    turkish: 'Turkish Title',
                },
                expirationDate: 'Expiration Date',
                lowStockLimit: 'Low Stock Limit',
                image: 'Image',
                description: {
                    english: 'Description',
                    turkish: 'Turkish Description',
                },
                category: {
                    id: 'Category',
                    name: {
                        turkish: 'Category',
                    },
                },
                secondCategory: {
                    id: 'Second Category',
                    name: {
                        turkish: 'Second Category',
                    },
                },
                price: 'Sale Price',
                stock: 'Stock',
                award: 'Award',
                newPrice: 'Discount Price',
                enableStockManagement: 'Stock Management Enabled?',
                purchasePrice: 'Purchase Price',
            },
        },
    },
    tr: {
        products: {
            name: 'Ürünler',
            general: 'Genel',
            names: 'Adı',
            fields: {
                name: {
                    english: 'İngilizce Adı',
                    turkish: 'Türkçe Adı',
                },
                amount: 'Miktar',
                unitId: 'Birim',
                expirationDate: 'Son kullanma tarihi',
                lowStockLimit: 'Düşük Stok Limiti',
                image: 'Resim',
                description: {
                    english: 'İngilizce Açıklama',
                    turkish: 'Türkçe Açıklama',
                },
                category: {
                    id: 'Kategori',
                    name: {
                        turkish: 'Kategori',
                    },
                },
                categoryId: 'Kategori',
                secondCategoryId: 'İkinci Kategori',
                price: 'Satiş fiyat',
                stock: 'Stok',
                award: 'Kredit',
                newPrice: 'İndirimli fiyat',
                enableStockManagement: 'Stok Yönetimi Aktif?',
                purchasePrice: 'Alış fiyatı',
            },
        },
    },
}
