import { minValue, NumberInput, required, SimpleForm } from 'react-admin'
import React from 'react'
import Grid from '@mui/material/Grid'
import { DateTimePickerInput } from '../../common/DateTimePickerInput'
import { endOfTomorrow } from 'date-fns'

type Prop = {
    edit: boolean
}
export default function EditCreateRankingView({ edit }: Prop) {
    return (
        <SimpleForm>
            <Grid container columnGap={2}>
                <DateTimePickerInput
                    source={'startedAt'}
                    min={edit ? undefined : new Date()}
                    required={true}
                    label={'Starts At'}
                />
                <DateTimePickerInput
                    source={'endsAt'}
                    defaultValue={edit ? undefined : endOfTomorrow()}
                    min={edit ? undefined : new Date()}
                    required={true}
                    label={'Ends At'}
                />
                <NumberInput
                    name={'expenses'}
                    source={'expenses'}
                    validate={[required(), minValue(1)]}
                    label={'Expenses'}
                />
            </Grid>
        </SimpleForm>
    )
}
