import React, { useEffect, useState } from 'react'
import { Card, CircularProgress } from '@mui/material'
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { fetchJson } from '../rest/fetch'
import { SERVER_IP } from '../index'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import ChartCommonInput from './chart-common-input'

const useStyles = makeStyles((theme) => ({
    main: {
        flex: '1',
        margin: '1em',
    },
    titleLink: { textDecoration: 'none', color: 'inherit' },
    card: {
        padding: '16px 0',
        overflow: 'inherit',
        textAlign: 'right',
        height: '470px',
    },
    title: {
        padding: '0 16px',
    },
    value: {
        padding: '0 16px',
        minHeight: 48,
    },
    avatar: {
        background: theme.palette.background.avatar,
    },
}))

function sevenDays() {
    let date = new Date()
    date.setTime(date.getTime() - 7 * 24 * 60 * 60 * 1000)
    return date
}

export function OrderCountChart() {
    const classes = useStyles()
    const [start, setStart] = useState(sevenDays())
    const [end, setEnd] = useState(new Date())
    const [loading, setLoading] = useState(false)
    const [period, setPeriod] = useState('DAY')
    const [data, setData] = useState([])

    useEffect(() => {
        setLoading(true)
        let body = {
            start: start.getTime(),
            end: end.getTime(),
            period: period,
        }
        let s = JSON.stringify(body)
        fetchJson(`${SERVER_IP}/orderReports/getCount`, { body: s, method: 'POST' }).then(
            (response) => {
                setLoading(false)
                if (response.json) {
                    setData(response.json)
                }
            },
            () => {
                console.log('Error occurred')
                setLoading(false)
            }
        )
    }, [start, end, period])

    return (
        <div className={classes.main}>
            <Typography variant={'body2'}>Orders</Typography>
            <Card className={classes.card} variant={'outlined'}>
                <ChartCommonInput
                    start={start}
                    end={end}
                    setStartDate={setStart}
                    setEndDate={setEnd}
                    period={period}
                    setPeriod={setPeriod}
                />
                {!loading && (
                    <ResponsiveContainer height={'87%'} width={'99%'}>
                        <LineChart data={data}>
                            <CartesianGrid vertical={false} strokeDasharray="3" />
                            <XAxis
                                dataKey="name"
                                tick={{ fill: '#212121' }}
                                stroke={'white'}
                                style={{ marginBottom: '2em' }}
                            />
                            <YAxis hide />
                            <Tooltip />
                            <Legend style={{ margin: '1em' }} />
                            <Line dataKey="orders" name={'Orders'} strokeWidth={2} stroke={'#3d5afe'} />
                            <Line dataKey="delivered" name={'Delivered'} strokeWidth={2} stroke={'#4caf50'} />
                            <Line dataKey="cancelled" name={'Cancelled'} strokeWidth={2} stroke={'#d32f2f'} />
                        </LineChart>
                    </ResponsiveContainer>
                )}
                {loading && (
                    <div style={{ textAlign: 'center', height: '100%' }}>
                        <CircularProgress color={'primary'} />
                    </div>
                )}
            </Card>
        </div>
    )
}
