import React from 'react'
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    Filter,
    maxLength,
    minLength,
    PasswordInput,
    ReferenceInput,
    ReferenceManyField,
    required,
    SelectInput,
    Show,
    ShowButton,
    SimpleForm,
    Tab,
    TabbedShowLayout,
    TextField,
    TextInput,
    usePermissions,
} from 'react-admin'
import UserActions from './UserActions'
import { OrderGrid } from '../products/Order'
import { ListPagination } from '../common/Pagination'
import { TimeField } from '../common/TimeField'
import { SearchInput } from '../common/SearchInput'
import { UserListActions } from './UserListActions'
import { RaFixedList } from '../common/ra-fixed-list'

const UserFilters = (props) => (
    <Filter {...props}>
        <SearchInput source={'name'} label={'Name'} alwaysOn />
        <SearchInput source={'username'} label={'Username'} alwaysOn />
        <BooleanInput source={'enabled'} />
    </Filter>
)

export const UserCreate = () => (
    <Create>
        <SimpleForm redirect={'list'}>
            <TextInput source={'name'} validate={[required(), maxLength(100)]} />
            <BooleanInput source={'enabled'} />
            <TextInput source={'username'} validate={[required(), minLength(4), maxLength(30)]} />
            <PasswordInput source={'password'} validate={[required(), minLength(6), maxLength(30)]} />
        </SimpleForm>
    </Create>
)

export const UserEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source={'name'} validate={required()} />
            <BooleanInput source={'enabled'} />
            <TextInput source={'username'} disabled validate={[required(), minLength(4)]} />
        </SimpleForm>
    </Edit>
)

export const UserList = () => {
    const { permissions } = usePermissions()
    return (
        <RaFixedList
            sort={{ field: 'id', order: 'DESC' }}
            actions={<UserListActions permissions={permissions} />}
            pagination={<ListPagination />}
            perPage={50}
            filters={<UserFilters />}
        >
            <Datagrid bulkActionButtons={false}>
                <TextField source={'name'} />
                <TextField source={'username'} />
                <TimeField source={'creationTime'} />
                <BooleanField source={'enabled'} />
                <ShowButton />
                {permissions === 'ROLE_ADMIN' && <EditButton />}
                {permissions === 'ROLE_ADMIN' && <DeleteWithConfirmButton />}
            </Datagrid>
        </RaFixedList>
    )
}

export const UserShow = () => {
    const { permissions } = usePermissions()
    return (
        <Show actions={permissions === 'ROLE_ADMIN' && <UserActions />}>
            <TabbedShowLayout>
                <Tab label={'resources.customers.general'}>
                    <TextField source={'name'} />
                    <TextField source={'username'} />
                    <BooleanField source={'enabled'} />
                </Tab>
                <Tab label={'resources.customers.addresses'}>
                    <ReferenceManyField reference={'addresses'} target={'customer.id'} addLabel={false}>
                        <AddressGrid permissions={permissions} />
                    </ReferenceManyField>
                </Tab>
                <Tab label={'resources.customers.orders'}>
                    <ReferenceManyField reference={'orders'} target={'customer.id'} addLabel={false}>
                        <OrderGrid />
                    </ReferenceManyField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
}
const AddressGrid = ({ permissions }) => (
    <Datagrid bulkActionButtons={false}>
        <TextField source={'name'} />
        <TextField source={'comment'} />
        <TextField source={'town.name'} />
        <BooleanField source={'active'} />
        {permissions === 'ROLE_ADMIN' && <EditButton />}
    </Datagrid>
)

export const EditAddress = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source={'name'} validate={required()} />
            <TextInput source={'comment'} fullWidth validate={required()} />
            <ReferenceInput source={'town.id'} perPage={1000} reference={'towns'} validate={required()}>
                <SelectInput optionText={'name'} />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
)

export const UserTranslations = {
    en: {
        customers: {
            name: 'Users',
            general: 'General',
            orders: 'Orders',
            addresses: 'Addresses',
            noCredit: 'No Credit',
            fields: {
                enabled: 'Enabled',
                name: 'Name',
                username: 'Username',
                password: 'Password',
                earnedCredit: 'Credit',
                creationTime: 'Registration Time',
            },
        },
    },
    tr: {
        customers: {
            name: 'Kullanıcılar',
            general: 'Genel',
            orders: 'Siparişler',
            addresses: 'Adresler',
            noCredit: 'Kredi Yok',
            fields: {
                enabled: 'Aktif',
                name: 'İsim',
                username: 'Kullanıcı adı',
                password: 'Şifre',
                earnedCredit: 'Kredi',
                creationTime: 'Kayıt Zamanı',
            },
        },
    },
}
export const AddressTranslations = {
    en: {
        addresses: {
            name: 'Addresses',
            fields: {
                town: {
                    name: 'Town',
                },
                name: 'Name',
                comment: 'Details',
                active: 'Active',
            },
        },
    },
    tr: {
        addresses: {
            name: 'Adresler',
            fields: {
                town: {
                    name: 'Mahalle',
                },
                name: 'Adı',
                comment: 'Detaylar',
                active: 'Aktif',
            },
        },
    },
}
