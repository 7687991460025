import * as React from 'react'
import { useState } from 'react'
import { serverExport } from '../Util'
import PdfIcon from '@mui/icons-material/PictureAsPdf'
import ExcelIcon from '@mui/icons-material/FormatAlignCenter'
import { LoadingButton } from '@mui/lab'

export function ExportButton({ path, pdf, total, filter, sort }) {
    const [loading, setLoading] = useState(false)

    function doExport() {
        setLoading(true)
        serverExport(path, sort, filter, pdf, () => {
            setLoading(false)
        })
    }

    return (
        <LoadingButton
            startIcon={pdf ? <PdfIcon /> : <ExcelIcon />}
            disabled={total === 0 || loading}
            onClick={() => doExport()}
            color={'primary'}
            size={'small'}
            loading={loading}
        >
            {pdf ? 'PDF Export' : 'Excel Export'}
        </LoadingButton>
    )
}
