import Box from '@mui/material/Box'
import { useBoolean } from 'src/hooks/use-boolean'
import { useResponsive } from 'src/hooks/use-responsive'
import { useSettingsContext } from 'src/components/settings'
import Main from './main'
import Header from './header'
import NavMini from './nav-mini'
import NavVertical from './nav-vertical'
import NavHorizontal from './nav-horizontal'
import React from 'react'
import { useLoading } from 'ra-core'
import { useGlobalState } from '../bread/global-context'
import { RotatingLines } from 'react-loader-spinner'
import { alpha } from '@mui/material/styles'
import { useTheme } from '@mui/styles'

type Props = {
    children: React.ReactNode
}

export default function DashboardLayout({ children }: Props) {
    const settings = useSettingsContext()
    const lgUp = useResponsive('up', 'lg')
    const nav = useBoolean()
    const loading = useLoading()
    const isHorizontal = settings.themeLayout === 'horizontal'
    const isMini = settings.themeLayout === 'mini'
    const { state } = useGlobalState()
    const theme = useTheme()
    // @ts-ignore
    const primary = theme.palette.primary.main
    const renderNavMini = <NavMini />
    const renderHorizontal = <NavHorizontal />
    const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />
    const renderLoading = (
        <Box
            sx={{
                padding: '10px',
                position: 'absolute',
                width: '100%',
                height: '100vh',
                alignContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                background: (theme) => alpha(theme.palette.background.default, 0.8),
            }}
        >
            <RotatingLines strokeColor={primary} strokeWidth="3" animationDuration="0.75" width="50" visible={true} />
        </Box>
    )
    if (isHorizontal) {
        return (
            <>
                <Header onOpenNav={nav.onTrue} />

                {lgUp ? renderHorizontal : renderNavVertical}

                <Main>{children}</Main>
            </>
        )
    }

    if (isMini) {
        return (
            <>
                <Header onOpenNav={nav.onTrue} />
                <Box
                    sx={{
                        minHeight: 1,
                        display: 'flex',
                        flexDirection: { xs: 'column', lg: 'row' },
                    }}
                >
                    {lgUp ? renderNavMini : renderNavVertical}
                    {(loading || state.loading) && renderLoading}
                    <Main>{children}</Main>
                </Box>
            </>
        )
    }

    return (
        <>
            <Header onOpenNav={nav.onTrue} />

            <Box
                sx={{
                    minHeight: 1,
                    display: 'flex',
                    flexDirection: { xs: 'column', lg: 'row' },
                }}
            >
                {renderNavVertical}
                {(loading || state.loading) && renderLoading}
                <Main>{children}</Main>
            </Box>
        </>
    )
}
