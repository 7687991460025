import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

//export const SERVER_IP = 'http://localhost:9045/antelope'
export const SERVER_IP = 'https://api.proshaa.com'
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)
serviceWorker.unregister()
