import { RankUser } from '../types'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import React from 'react'

type Prop = {
    user: RankUser
}
export default function RankingHistoryRow({ user }: Prop) {
    return (
        <TableRow key={user.id}>
            <TableCell>{user.id}</TableCell>
            <TableCell>{user.title}</TableCell>
            <TableCell>{user.points.toFixed(2)}</TableCell>
            <TableCell>{user.payback.toFixed(2)}</TableCell>
        </TableRow>
    )
}
