import React, { useState } from 'react'
import { useMountEffect } from '../Util'
import { SERVER_IP } from '../index'
import makeStyles from '@mui/styles/makeStyles'
import { CardHeader, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material'
import Paper from '@mui/material/Paper'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { Title, useTranslate } from 'react-admin'
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc'
import Button from '@mui/material/Button'
import OrderIcon from '@mui/icons-material/ViewQuilt'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: 10,
        userSelect: 'none',
        borderRadius: 10,
    },
    titleDiv: {
        marginTop: 10,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        background: theme.palette.secondary.main,
    },
    iconDiv: {
        height: 150,
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        fontSize: 'medium',
        fontWeight: 'bold',
        lineHeight: '50px',
        color: 'white',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxHeight: 50,
        display: 'block',
    },
    paperDiv: {
        textAlign: 'center',
        alignContent: 'center',
    },
    icon: {
        width: 100,
        height: 100,
        pointerEvents: 'none',
    },
}))

const gridStyles = {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gridGap: '16px',
}

const gridItemStyles = {
    height: '300px',
}

export default function ProductReOrder({ ...props }) {
    const classes = useStyles()
    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [message, setMessage] = useState({ text: '', state: false })
    const translate = useTranslate()

    useMountEffect(() => {
        let category = props.location.pathname.replace('/productReorder/', '')
        setLoading(true)
        let headers = new Headers()
        headers.append('Authorization', `Bearer ${localStorage.getItem('token')}`)
        headers.append('X-API-KEY', '0500a961-8f5e-4d83-a3b6-174471ef9164')

        const abortController = new AbortController()
        let aborted = false
        fetch(`${SERVER_IP}/products/getSeq/${category}`, {
            headers: headers,
            signal: abortController.signal,
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                setLoading(false)
                if (Array.isArray(data)) {
                    setProducts(data)
                }
            })
            .catch((e) => {
                if (!aborted) {
                    setLoading(false)
                }
                console.log(e)
            })
        return function cleanup() {
            aborted = true
            abortController.abort()
        }
    })

    const onSortEnd = ({ oldIndex, newIndex }) => {
        products[oldIndex].sequence = newIndex
        const deps = arrayMove(products, oldIndex, newIndex)
        setProducts(deps)
    }

    const onSave = () => {
        setSaving(true)
        let headers = new Headers()
        headers.append('Content-Type', 'application/json')
        headers.append('Authorization', `Bearer ${localStorage.getItem('token')}`)
        headers.append('X-API-KEY', '0500a961-8f5e-4d83-a3b6-174471ef9164')

        let body = products.map((dep, index) => ({ id: dep.id, seq: index }))
        fetch(`${SERVER_IP}/products/updateSeq`, { method: 'POST', headers: headers, body: JSON.stringify(body) })
            .then((response) => {
                return response.json()
            })
            .then(() => {
                setMessage({ text: translate('productOrder.success'), state: true })
                setSaving(false)
            })
            .catch((e) => {
                setMessage({ text: `${translate('productOrder.error')} ${e}`, state: true })
                setSaving(false)
            })
    }

    return (
        <Card>
            <Title title={translate('productOrder.title')} />
            <CardHeader title={<span>{translate('productOrder.message')}</span>} />
            <CardContent>
                {loading ? (
                    <CircularProgress size={24} />
                ) : (
                    <div style={{ height: 600, overflow: 'scroll' }}>
                        <SortableGrid
                            departments={products}
                            onSortEnd={onSortEnd}
                            axis={'xy'}
                            distance={10}
                            useWindowAsScrollContainer={true}
                            helperClass="sortableHelper"
                            classes={classes}
                        />
                    </div>
                )}
                <Button variant={'contained'} disabled={saving} size={'large'} onClick={onSave} color={'secondary'}>
                    {translate('productOrder.save')}
                    {saving && <CircularProgress size={20} />}
                </Button>
            </CardContent>
            <Dialog open={message.state}>
                <DialogContent>
                    <DialogContentText>{message.text}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setMessage({ state: false, text: message.text })} color={'secondary'}>
                        {translate('productOrder.ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    )
}

const SortableGrid = SortableContainer(({ departments, classes }) => (
    <div style={gridStyles}>
        {departments.map((tile, index) => (
            <DraggableTile tile={tile} cols={1} key={tile.id} index={index} classes={classes} />
        ))}
    </div>
))

const DraggableTile = SortableElement(({ tile, classes }) => (
    <div style={gridItemStyles}>
        <Paper className={classes.paper}>
            <div className={classes.paperDiv}>
                <div className={classes.iconDiv}>
                    <img className={classes.icon} src={tile.image} alt={tile.name.english} />
                </div>
                <div className={classes.titleDiv}>
                    <span className={classes.title}>{tile.name.english}</span>
                </div>
            </div>
        </Paper>
    </div>
))

export const ProductReOrderButton = (props) => {
    if (props.record) {
        return (
            <Button
                variant={'text'}
                color={'primary'}
                startIcon={<OrderIcon />}
                component={Link}
                to={`/productReorder/${props.record.id}`}
            >
                Products Orders
            </Button>
        )
    }
    return ''
}

export const ProductOrderTranslations = {
    en: {
        productOrder: {
            title: 'Arrange Products',
            success: 'Products sequence updated successfully',
            error: 'Failed to update products sequence.',
            ok: 'OK',
            save: 'Save',
            message: 'Drag to arrange products',
        },
    },
    tr: {
        productOrder: {
            title: 'Ürünler Düzenle',
            success: 'Ürünların sıralaması başarıyla güncellendi',
            error: 'Ürğnlarınların sırası güncellenemedi.',
            ok: 'Tamam',
            save: 'Kaydet',
            message: 'Ürünları düzenlemek için sürükleyin',
        },
    },
}
