import React, { useState } from 'react'
import SendIcon from '@mui/icons-material/Refresh'
import { Button, DialogActions, Snackbar } from '@mui/material'
import ResetDeviceView from './ResetDeviceView'
import { useRecordContext, useTranslate } from 'react-admin'

export default function UserShowActions() {
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState('')
    const translate = useTranslate()
    const data = useRecordContext()
    return (
        <DialogActions>
            {data !== undefined && (
                <Button variant={'text'} color={'primary'} onClick={() => setOpen(true)}>
                    <SendIcon /> {translate('users.resetDevice.reset')}
                </Button>
            )}
            <ResetDeviceView
                open={open}
                onResponse={(message, open) => {
                    setMessage(message)
                    setOpen(open)
                }}
                record={data}
            />
            <Snackbar
                open={message && message.length > 0}
                message={message}
                autoHideDuration={5000}
                onClose={() => {
                    setMessage('')
                }}
            />
        </DialogActions>
    )
}
