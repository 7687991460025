import React, { useState } from 'react'
import { Button, Dialog, DialogActions } from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { fetchJson } from '../rest/fetch'
import { SERVER_IP } from '../index'
import CircularProgress from '@mui/material/CircularProgress'
import { useTranslate } from 'react-admin'

export default function MarkOutOfStockView({ onResponse, record, open }) {
    const translate = useTranslate()
    const [loading, setLoading] = useState(false)
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
    }

    const handleSend = () => {
        if (record === undefined) {
            return
        }
        setLoading(true)
        let id
        if (Array.isArray(record)) {
            id = record.toString()
        } else {
            id = record.id
        }
        fetchJson(`${SERVER_IP}/products/outOfStock/${id}`, { method: 'POST' }).then(
            () => {
                setLoading(false)
                onResponse(translate('outOfStockView.done'), false)
            },
            () => {
                setLoading(false)
                onResponse(translate('outOfStockView.failed'), false)
            }
        )
    }

    let title = translate('outOfStockView.title')
    if (record !== undefined) {
        if (Array.isArray(record)) {
            title = title + record.toString()
        } else {
            title = title + record.id
        }
    }
    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{translate('outOfStockView.message')}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={loading}
                    variant={'text'}
                    color={'primary'}
                    onClick={() => {
                        onResponse(null, false)
                    }}
                >
                    {translate('outOfStockView.cancel')}
                </Button>
                <Button disabled={loading} variant={'text'} color={'primary'} onClick={handleSend}>
                    {loading && <CircularProgress size={25} thickness={2} />}
                    {translate('outOfStockView.yes')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export const MarkOutOfStockTranslations = {
    en: {
        outOfStockView: {
            title: 'Mark Out Of Stock #',
            message: 'The selected products(s) will be marked as out of stock. Are you sure to continue?',
            cancel: 'No',
            done: 'Products(s) successfully marked out of stock',
            failed: 'Failed to mark products(s) as out of stock.',
            yes: 'Yes',
        },
    },
    tr: {
        outOfStockView: {
            title: 'Stok dışı olarak belirle#',
            message: 'Seçilen ürünler stok dışı olarak belirlenicek,devam etmek istiyormusunuz?',
            cancel: 'Hayır',
            done: 'Başarılı',
            failed: 'Hata.',
            yes: 'Evet',
        },
    },
}
