import { AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS, AUTH_LOGIN, AUTH_LOGOUT } from 'react-admin'
import { SERVER_IP } from '../index'
import { jwtDecode } from 'jwt-decode'

export default async (type, params) => {
    if (type === AUTH_LOGIN) {
        const { username, password } = params
        const body = {
            username: username,
            password: password,
        }
        let url = `${SERVER_IP}/admins/login`
        let headers = new Headers()
        headers.set('Content-Type', 'application/json')
        headers.append('X-API-KEY', '0500a961-8f5e-4d83-a3b6-174471ef9164')

        const request = new Request(url, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: headers,
        })

        const res = await fetch(request)
        if (res.status < 200 || res.status >= 300) {
            throw new Error('login.loginFailed')
        }
        const { accessToken, name } = await res.json()
        localStorage.setItem('prosha.user.name', name)
        localStorage.setItem('token', accessToken)
        return await Promise.resolve()
    }
    if (type === AUTH_LOGOUT) {
        localStorage.removeItem('token')
        return Promise.resolve()
    }
    if (type === AUTH_ERROR) {
        const { status } = params
        if (status === 401 || status === 403) {
            localStorage.removeItem('token')
            return Promise.reject('unknown')
        } else if (localStorage.getItem('token')) {
            return Promise.resolve()
        }
        return Promise.reject('Please login to continue')
    }
    if (type === AUTH_CHECK) {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject('unknown')
    }
    if (type === AUTH_GET_PERMISSIONS) {
        const accessToken = localStorage.getItem('token')
        try {
            const { authority } = jwtDecode(accessToken)
            return authority ? Promise.resolve(authority) : Promise.reject()
        } catch (e) {
            return Promise.reject()
        }
    }
    return Promise.reject('Unknown method')
}
