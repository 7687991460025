import React, { forwardRef } from 'react'
import Link from '@mui/material/Link'
import Box, { BoxProps } from '@mui/material/Box'
import { RouterLink } from 'src/routes/components'
import LogoUrl from './logo.png'

export interface LogoProps extends BoxProps {
    disabledLink?: boolean
}

// eslint-disable-next-line react/display-name
const Logo = forwardRef<HTMLDivElement, LogoProps>(({ disabledLink = false, sx, ...other }, ref) => {
    const logo = (
        <Box
            ref={ref}
            component="div"
            sx={{
                width: 130,
                height: 30,
                display: 'inline-flex',
                ...sx,
            }}
            {...other}
        >
            <img src={LogoUrl} />
        </Box>
    )

    if (disabledLink) {
        return logo
    }

    return (
        <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
            {logo}
        </Link>
    )
})

export default Logo
