import React from 'react'
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    maxLength,
    minLength,
    PasswordInput,
    ReferenceManyField,
    required,
    Show,
    ShowButton,
    SimpleForm,
    Tab,
    TabbedShowLayout,
    TextField,
    TextInput,
    usePermissions,
} from 'react-admin'
import DriverActions from './UserActions'
import { OrderGrid } from '../products/Order'
import { RaFixedList } from '../common/ra-fixed-list'

export const DriverCreate = () => (
    <Create>
        <SimpleForm redirect={'list'}>
            <TextInput source={'name'} validate={[required(), maxLength(100)]} />
            <TextInput source={'plateNo'} validate={[required(), maxLength(12)]} />
            <BooleanInput source={'enabled'} />
            <TextInput source={'username'} validate={[required(), minLength(4), maxLength(30)]} />
            <PasswordInput source={'password'} validate={[required(), minLength(6), maxLength(30)]} />
        </SimpleForm>
    </Create>
)

export const DriverEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source={'name'} validate={required()} />
            <TextInput source={'plateNo'} validate={[required(), maxLength(12)]} />
            <BooleanInput source={'enabled'} />
            <TextInput source={'username'} disabled validate={[required(), minLength(4)]} />
            <PasswordInput source={'password'} disabled validate={[required(), minLength(6)]} />
        </SimpleForm>
    </Edit>
)

export const DriverList = () => {
    const { permissions } = usePermissions()
    return (
        <RaFixedList sort={{ field: 'id', order: 'DESC' }}>
            <Datagrid bulkActionButtons={false}>
                <TextField source={'name'} />
                <TextField source={'username'} />
                <TextField source={'plateNo'} />
                <BooleanField source={'enabled'} />
                <ShowButton />
                {permissions === 'ROLE_ADMIN' && <EditButton />}
                {permissions === 'ROLE_ADMIN' && <DeleteWithConfirmButton />}
            </Datagrid>
        </RaFixedList>
    )
}

export const DriverShow = () => (
    <Show actions={<DriverActions />}>
        <TabbedShowLayout>
            <Tab label={'resources.drivers.general'}>
                <TextField source={'name'} />
                <TextField source={'username'} />
                <TextField source={'plateNo'} />
                <BooleanField source={'enabled'} />
            </Tab>
            <Tab label={'resources.drivers.orders'}>
                <ReferenceManyField reference={'orders'} target={'driver.id'} addLabel={false}>
                    <OrderGrid />
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
)
export const DriverTranslations = {
    en: {
        drivers: {
            name: 'Drivers',
            general: 'General',
            orders: 'Assigned Orders',
            fields: {
                enabled: 'Enabled',
                name: 'Name',
                username: 'username',
                password: 'Password',
                plateNo: 'Car Plate',
            },
        },
    },
    tr: {
        drivers: {
            name: 'Sürücüler',
            general: 'Genel',
            orders: 'Atanan siparişler',
            fields: {
                enabled: 'Aktif',
                name: 'İsim',
                username: 'Kullancı Adı',
                password: 'Şifre',
                plateNo: 'Araç Plaka',
            },
        },
    },
}
