import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, InputLabel, MenuItem, Select } from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import CircularProgress from '@mui/material/CircularProgress'
import { SERVER_IP } from '../index'
import { fetchJson } from '../rest/fetch'
import FormControl from '@mui/material/FormControl'

export function OrderConfirmDialog({ id, open, onClose }) {
    const [drivers, setDrivers] = useState([])
    const [loading, setLoading] = useState(false)
    const [driver, setDriver] = useState(-1)
    useEffect(() => {
        setLoading(true)
        fetchJson(`${SERVER_IP}/drivers/get`)
            .then(({ json }) => {
                setDrivers(json.content)
            })
            .catch((e) => {
                console.log(e)
            })
            .finally(() => setLoading(false))
    }, [])

    function handleConfirm() {
        setLoading(true)
        fetchJson(`${SERVER_IP}/orders/assign/${id}/${driver}`, { method: 'POST' })
            .then(() => {
                onClose(true, 'Order confirmed!')
            })
            .catch((e) => {
                console.log(e)
                onClose(false, 'Failed to confirm order')
            })
            .finally(() => {
                setDriver(-1)
                setLoading(false)
            })
    }

    function onDriverSelect(event) {
        setDriver(event.target.value)
    }

    return (
        <Dialog open={open} onClose={() => onClose(false, null)} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{`Confirm Order #${id}`}</DialogTitle>
            <DialogContent>
                <DialogContentText>Please select a driver to confirm order.</DialogContentText>
                <FormControl fullWidth>
                    <InputLabel id="driver-select-label">Select Driver</InputLabel>
                    <Select id="driver-select" value={driver} label="Driver" onChange={onDriverSelect}>
                        {drivers.map((d) => (
                            <MenuItem value={d.id} key={d.id}>
                                {d.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={loading}
                    variant={'text'}
                    color={'primary'}
                    onClick={() => {
                        onClose(false, null)
                    }}
                >
                    Cancel
                </Button>
                <Button
                    disabled={loading || driver === -1}
                    variant={'text'}
                    color={'secondary'}
                    onClick={handleConfirm}
                >
                    {loading && <CircularProgress size={25} thickness={2} />}
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
}
