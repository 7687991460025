import { List, ListProps, useResourceContext } from 'react-admin'
import React, { useEffect } from 'react'

export const RaFixedList = ({ children, ...other }: ListProps) => {
    const resource = useResourceContext()
    useEffect(() => {
        const key = `RaStore.${resource}.listParams`
        localStorage.removeItem(key)
    }, [])
    return (
        <List {...other} storeKey={false}>
            {children}
        </List>
    )
}
