import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import Dropzone from 'react-dropzone'
import { SERVER_IP } from '../index'
import { fetchJson } from '../rest/fetch'
import { useFormControl } from '@mui/material'
import { useController } from 'react-hook-form'

const styles = {
    dropzone: {
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
        background: '#d5d5d5',
        minHeight: 100,
        textAlign: 'center',
    },
    progress: {
        display: 'flex',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '50%',
        marginTop: 100,
    },
}
export default function ImageUpload({ source, required }) {
    const [uploading, setUploading] = useState(false)
    const translate = useTranslate()
    const control = useFormControl()
    const onDrop = (files) => {
        upload(files[0])
    }
    const { field } = useController({
        source,
        control,
        name: source,
        rules: { required: required },
    })

    const upload = (file) => {
        setUploading(true)
        const formData = new FormData()
        formData.append('file', file)
        const url = SERVER_IP + '/upload/uploadImage'

        fetchJson(url, { body: formData, method: 'POST' })
            .then(({ body }) => {
                try {
                    const file = JSON.parse(body)
                    field.onChange(file.url)
                } catch (e) {}
            })
            .catch(() => {
                //ignore
            })
            .finally(() => {
                setUploading(false)
            })
    }

    const onRemove = () => {
        let fileUrl = field.value
        field.onChange(null)
        let url = `${SERVER_IP}/upload/delete?url=${encodeURI(fileUrl)}`

        fetchJson(url, { method: 'DELETE' })
            .then(() => {
                //ignore
            })
            .catch(() => {})
    }

    if (field.value != null) {
        return (
            <div style={styles.progress}>
                <span>
                    <img src={field.value} height={400} width={400} alt="" />
                    <div>
                        <Button color={'primary'} onClick={onRemove}>
                            {translate('uploadImage.remove')}
                        </Button>
                    </div>
                </span>
            </div>
        )
    } else if (uploading) {
        return (
            <div style={styles.progress}>
                <CircularProgress size={60} />
                <h2>{translate('uploadImage.uploading')}</h2>
            </div>
        )
    }
    return (
        <div style={styles.dropzone}>
            <Dropzone
                onDrop={onDrop}
                multiple={false}
                maxSize={1024 * 5 * 1024}
                accept={{
                    'image/*': [],
                }}
            >
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p style={{ padding: '50px' }}>
                            {translate('uploadImage.selectMessage')} <b>{translate('uploadImage.max')} </b>
                        </p>
                    </div>
                )}
            </Dropzone>
        </div>
    )
}

export const ImageUploadTranslation = {
    en: {
        uploadImage: {
            selectMessage: 'Drop image here, or click to select image',
            max: '5MB',
            uploading: 'Uploading Image...',
            remove: 'Remove',
        },
    },
    tr: {
        uploadImage: {
            selectMessage: 'Resmi buraya bırakın veya resim seçmek için tıklayın',
            max: '5MB',
            uploading: 'Resim Yükleniyor...',
            remove: 'Sil',
        },
    },
}
