import React, { forwardRef } from 'react'
import { Link, LinkProps } from 'react-router-dom'

interface RouterLinkProps extends Omit<LinkProps, 'to'> {
    href: string
}

// eslint-disable-next-line react/display-name
const RouterLink = forwardRef<HTMLAnchorElement, RouterLinkProps>(({ href, ...other }, ref) => (
    <Link ref={ref} to={href} {...other} />
))

export default RouterLink
