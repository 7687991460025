import Box from '@mui/material/Box'
import React, { useEffect } from 'react'
import { fetchJson } from '../../rest/fetch'
import { SERVER_IP } from '../../index'
import { useBoolean } from '../../hooks/use-boolean'
import { Chip, CircularProgress, DialogContentText } from '@mui/material'
import Switch from '@mui/material/Switch'
import { ConfirmDialog } from '../../components/custom-dialog'
import Button from '@mui/material/Button'
import { useSnackbar } from 'notistack'
import Stack from '@mui/material/Stack'

export default function SystemStatusButton() {
    const loading = useBoolean()
    const active = useBoolean()
    const confirm = useBoolean()
    const { enqueueSnackbar } = useSnackbar()
    useEffect(() => {
        loading.onTrue()
        void load()
    }, [])

    const load = async () => {
        fetchJson(`${SERVER_IP}/appConfigs/systemStatus/get`)
            .then(({ json }) => {
                if (json.value === 'true') {
                    active.onTrue()
                } else {
                    active.onFalse()
                }
            })
            .catch((e) => {
                console.log(e)
            })
            .finally(loading.onFalse)
    }

    const updateStatus = async () => {
        loading.onTrue()
        confirm.onFalse()
        await fetchJson(`${SERVER_IP}/appConfigs/systemStatus/update?enable=${!active.value}`, {
            method: 'POST',
        })
            .then(({ json }) => {
                if (json.value === 'true') {
                    active.onTrue()
                } else {
                    active.onFalse()
                }
                enqueueSnackbar('Successfully changed system status', { variant: 'success' })
            })
            .catch(() => {
                enqueueSnackbar('Failed to change system status', { variant: 'error' })
            })
            .finally(loading.onFalse)
    }

    return (
        <Box>
            <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={1}>
                {loading.value && <CircularProgress size={24} color={'primary'} />}
                <Chip
                    clickable={true}
                    sx={{
                        '& .MuiChip-icon': {
                            marginLeft: '-4px',
                        },
                    }}
                    disabled={loading.value}
                    icon={<Switch sx={{ mr: 10 }} checked={active.value} />}
                    onClick={confirm.onTrue}
                    label={active.value ? 'System Active' : 'System Inactive'}
                    variant={'outlined'}
                />
            </Stack>
            <ConfirmDialog
                open={confirm.value}
                title={'Confirm Changing System Status?'}
                action={
                    <Button color={'error'} variant={'outlined'} onClick={updateStatus}>
                        Yes
                    </Button>
                }
                onClose={confirm.onFalse}
                content={
                    <DialogContentText>
                        Do you really want to change the system active status? If you close system users will not be
                        able to place orders.
                    </DialogContentText>
                }
            />
        </Box>
    )
}
