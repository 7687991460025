import React from 'react'
import PendingOrder from './PendingOrder'
import { SaleCountChart } from './SaleCountChart'
import { OrderCountChart } from './OrderCountChart'
import { SaleRefundCountChart } from './SaleRefundCountChart'
import { SaleMoneyChart } from './SaleMoneyChart'
import { RankingCard } from './RankingCard'
import { usePermissions } from 'react-admin'
import Box from '@mui/material/Box'
import { HEADER, NAV } from '../layout/config-layout'
import { useSettingsContext } from '../components/settings'
import { useResponsive } from '../hooks/use-responsive'
import Stack from '@mui/material/Stack'

const styles = {
    flex: { display: 'flex', marginBottom: '1em', maxWidth: '100%' },
    leftCol: { width: '100%', margin: '1em' },
    rightCol: { width: '100%', margin: '1em' },
    singleCol: { marginTop: '2em' },
}

export default function Dashboard() {
    const { permissions } = usePermissions()

    if (permissions !== 'ROLE_ADMIN') {
        return (
            <Box sx={{ width: '100%' }}>
                <div style={styles.flex}>
                    <div style={styles.rightCol}>
                        <PendingOrder />
                    </div>
                </div>
            </Box>
        )
    }
    return (
        <Box sx={{ width: '100%' }}>
            <PendingOrder />
            <Stack spacing={1} direction={'row'}>
                <RankingCard />
                <SaleMoneyChart />
            </Stack>
            <Stack spacing={1} direction={'row'}>
                <SaleCountChart refund={false} />
                <SaleCountChart refund={true} />
            </Stack>
            <Stack spacing={1} direction={'row'}>
                <SaleRefundCountChart />
                <OrderCountChart />
            </Stack>
        </Box>
    )
}
