import React from 'react'
import Icon from '@mui/icons-material/House'
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    Edit,
    EditButton,
    maxLength,
    maxValue,
    minLength,
    minValue,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    required,
    SelectInput,
    TabbedForm,
    TextField,
    TextInput,
} from 'react-admin'
import { ListPagination } from '../common/Pagination'
import { MapInput } from '../common/map/MapView'
import { RaFixedList } from '../common/ra-fixed-list'

export const TownIcon = Icon

export const TownList = () => (
    <RaFixedList sort={{ field: 'id', order: 'DESC' }} perPage={50} pagination={<ListPagination />}>
        <Datagrid bulkActionButtons={false}>
            <TextField source={'name'} />
            <TextField source={'zip'} />
            <ReferenceField source={'district.id'} reference={'districts'}>
                <TextField source={'name'} />
            </ReferenceField>
            <TextField source={'minimumOrder'} />
            <TextField source={'deliveryFee'} />
            <TextField source={'freeDeliveryLimit'} />
            <BooleanField source={'deliveryFree'} />
            <TextField source={'minimumDeliveryMinutes'} />
            <TextField source={'maximumDeliveryMinutes'} />
            <BooleanField source={'active'} />
            <EditButton />
        </Datagrid>
    </RaFixedList>
)

export const TownCreate = () => (
    <Create>
        <CreateOrEdit />
    </Create>
)

export const TownEdit = () => (
    <Edit>
        <CreateOrEdit />
    </Edit>
)

const CreateOrEdit = () => (
    <TabbedForm redirect={'list'} syncWithLocation={false}>
        <TabbedForm.Tab label={'resources.towns.fields.location'}>
            <MapInput source={'location'} required={true} />
        </TabbedForm.Tab>
        <TabbedForm.Tab label={'resources.towns.general'}>
            <TextInput source={'name'} validate={required()} />
            <NumberInput source={'zip'} validate={[required(), minLength(3), maxLength(7)]} />
            <ReferenceInput perPage={1000} source={'district.id'} reference={'districts'} validate={required()}>
                <SelectInput optionText={'name'} />
            </ReferenceInput>
            <NumberInput source={'deliveryFee'} />
            <NumberInput source={'freeDeliveryLimit'} />
            <NumberInput source={'minimumOrder'} validate={[required(), minValue(1)]} />
            <BooleanInput source={'deliveryFree'} />
            <NumberInput source={'minimumDeliveryMinutes'} />
            <NumberInput source={'maximumDeliveryMinutes'} />
            <BooleanInput source={'active'} />
        </TabbedForm.Tab>
        <TabbedForm.Tab label={'Saatler'}>
            <NumberInput
                source={'shift.open.hour'}
                label={'Açık Saat'}
                options={{ helperText: '0 demek 12 gece.' }}
                validate={[minValue(0), maxValue(23)]}
            />
            <NumberInput source={'shift.open.minute'} label={'Açık Dakika'} validate={[minValue(0), maxValue(59)]} />
            <NumberInput label={'Kapalı Saat'} source={'shift.close.hour'} validate={[minValue(0), maxValue(23)]} />
            <NumberInput label={'Kapalı Dakika'} source={'shift.close.minute'} validate={[minValue(0), maxValue(59)]} />
        </TabbedForm.Tab>
    </TabbedForm>
)

export const TownTranslations = {
    en: {
        towns: {
            name: 'Towns',
            general: 'General',
            fields: {
                name: 'Name',
                location: 'Location',
                active: 'Active',
                deliveryFree: 'Delivery Free',
                deliveryFee: 'Delivery Fee',
                freeDeliveryLimit: 'Free Delivery Limit',
                district: {
                    id: 'District',
                },
            },
        },
    },
    tr: {
        towns: {
            name: 'Mahalle',
            general: 'Genel',
            fields: {
                name: 'Adı',
                location: 'Konum',
                active: 'Aktif',
                deliveryFree: 'Bedava Teslimat?',
                deliveryFee: 'Teslimat Ücreti',
                freeDeliveryLimit: 'Bedava teslimat ücreti alışveriş limiti',
                district: {
                    id: 'İlçe',
                },
            },
        },
    },
}
