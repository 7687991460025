import React from 'react'
import { Filter, SelectInput, useListContext, useListFilterContext, useTranslate } from 'react-admin'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import 'jspdf-autotable'
import { pdfExport } from '../Util'
import { OrderListActions } from './OrderReportListActions'
import { startOfYesterday } from 'date-fns'
import trLocale from 'date-fns/locale/tr'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DesktopDatePicker, DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { useFormContext } from 'react-hook-form'
import { RaFixedList } from '../common/ra-fixed-list'

const periods = [
    { id: 'DAY', name: 'resources.orderReports.period.day' },
    { id: 'WEEK', name: 'resources.orderReports.period.week' },
    { id: 'MONTH', name: 'resources.orderReports.period.month' },
    { id: 'YEAR', name: 'resources.orderReports.period.year' },
]
const orderExporter = (items) => {
    const itemsForExport = items.map((item) => {
        return [
            displayableDate(item.date),
            item.received.count,
            item.received.price,
            item.delivered.count,
            item.delivered.price,
            item.cancelled.count,
            item.cancelled.price,
            item.purchasePrice,
            item.profit,
            item.adjustedPrice,
        ]
    })

    const title = 'Order Report'
    let headers
    headers = [
        [
            'Date',
            'Received',
            'Received Amount',
            'Cancelled',
            'Cancelled Amount',
            'Completed',
            'Completed Amount',
            'Purchase Price',
            'Profit',
            'Adjusted Profit',
        ],
    ]
    pdfExport(title, itemsForExport, headers)
}
export const DateInputField = ({ name, label, min, disabled, start, justDate }) => {
    const { setValue, trigger, getValues } = useFormContext()
    return (
        <DatePickerView
            start={start}
            label={label}
            min={min}
            value={getValues(name)}
            disabled={disabled}
            justDate={justDate}
            setValue={(value) => {
                setValue(name, value)
                trigger(name)
            }}
        />
    )
}

export const DatePickerView = ({ label, min, disabled, justDate, setValue, value }) => {
    const translate = useTranslate()
    if (justDate) {
        return (
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={trLocale}>
                <DesktopDatePicker
                    disabled={disabled}
                    value={value ? new Date(value) : startOfYesterday()}
                    disableFuture
                    label={translate(label)}
                    minDate={min}
                    format={'dd.MM.yyyy'}
                    slotProps={{ textField: { size: 'small' } }}
                    onChange={(date) => {
                        setValue(date.getTime())
                    }}
                />
            </LocalizationProvider>
        )
    }
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={trLocale}>
            <DesktopDateTimePicker
                disabled={disabled}
                value={value ? new Date(value) : startOfYesterday()}
                disableFuture
                label={translate(label)}
                minDate={min}
                ampm={false}
                format={'dd.MM.yyyy HH:mm:ss'}
                slotProps={{ textField: { size: 'small' } }}
                onChange={(date) => {
                    setValue(date.getTime())
                }}
            />
        </LocalizationProvider>
    )
}

export const Filters = (props) => {
    const { filterValues } = useListFilterContext()
    let start = filterValues.start
    let end = filterValues.end
    let startDate = new Date()
    let endDate = new Date()
    if (start !== undefined) {
        startDate.setTime(start)
    }
    if (end !== undefined) {
        endDate.setTime(end)
    }
    return (
        <Filter {...props}>
            <SelectInput source={'period'} choices={periods} alwaysOn />
            <DateInputField justDate name={'start'} source={'start'} max={new Date()} alwaysOn />
            <DateInputField justDate name={'end'} max={new Date()} source={'end'} alwaysOn />
        </Filter>
    )
}

const OrderReportGird = () => {
    const translate = useTranslate()
    const { data } = useListContext()
    let purchasePrice = 0
    let profit = 0
    let adjustedProfit = 0
    let payback = 0
    let expense = 0
    let received = {
        total: 0,
        amount: 0,
    }

    let completed = {
        total: 0,
        amount: 0,
    }

    let cancelled = {
        total: 0,
        amount: 0,
    }
    if (data === undefined) {
        return <></>
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <b>{translate('resources.orderReports.date')}</b>
                    </TableCell>
                    <TableCell>
                        <b>{translate('resources.orderReports.received')}</b>
                    </TableCell>
                    <TableCell>
                        <b>{translate('resources.orderReports.receivedAmount')}</b>
                    </TableCell>
                    <TableCell>
                        <b>{translate('resources.orderReports.cancelled')}</b>
                    </TableCell>
                    <TableCell>
                        <b>{translate('resources.orderReports.cancelledAmount')}</b>
                    </TableCell>
                    <TableCell>
                        <b>{translate('resources.orderReports.completed')}</b>
                    </TableCell>
                    <TableCell>
                        <b>{translate('resources.orderReports.completedAmount')}</b>
                    </TableCell>
                    <TableCell>
                        <b>Harcama</b>
                    </TableCell>
                    <TableCell>
                        <b>Kazanç</b>
                    </TableCell>
                    <TableCell>
                        <b>Yarışma Masrafı</b>
                    </TableCell>
                    <TableCell>
                        <b>Düzeltilmiş kazanç</b>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((record, id) => {
                    received.total += record.received.count
                    received.amount += record.received.price
                    profit += record.profit
                    adjustedProfit += record.adjustedProfit
                    payback += record.payback
                    expense += record.expenses
                    purchasePrice += record.purchasePrice
                    cancelled.total += record.cancelled.count
                    cancelled.amount += record.cancelled.price
                    completed.total += record.delivered.count
                    completed.amount += record.delivered.price
                    return (
                        <TableRow key={id}>
                            <TableCell>
                                <span>{displayableDate(record.date)}</span>
                            </TableCell>
                            <TableCell>
                                <span>{record.received.count}</span>
                            </TableCell>
                            <TableCell>
                                {record.received.price.toLocaleString(undefined, {
                                    style: 'currency',
                                    currency: 'TRY',
                                })}
                            </TableCell>
                            <TableCell>
                                <span>{record.cancelled.count}</span>
                            </TableCell>
                            <TableCell>
                                {record.cancelled.price.toLocaleString(undefined, {
                                    style: 'currency',
                                    currency: 'TRY',
                                })}
                            </TableCell>
                            <TableCell>
                                <span>{record.delivered.count}</span>
                            </TableCell>
                            <TableCell>
                                {record.delivered.price.toLocaleString(undefined, {
                                    style: 'currency',
                                    currency: 'TRY',
                                })}
                            </TableCell>
                            <TableCell>
                                {record.purchasePrice.toLocaleString(undefined, {
                                    style: 'currency',
                                    currency: 'TRY',
                                })}
                            </TableCell>
                            <TableCell>
                                {record.profit.toLocaleString(undefined, {
                                    style: 'currency',
                                    currency: 'TRY',
                                })}
                            </TableCell>
                            <TableCell>
                                {record.expenses.toLocaleString(undefined, {
                                    style: 'currency',
                                    currency: 'TRY',
                                })}
                            </TableCell>
                            <TableCell>
                                {record.adjustedProfit.toLocaleString(undefined, {
                                    style: 'currency',
                                    currency: 'TRY',
                                })}
                            </TableCell>
                        </TableRow>
                    )
                })}
                <TableRow key={'total'}>
                    <TableCell>
                        <b>{translate('resources.orderReports.total')}</b>
                    </TableCell>
                    <TableCell>
                        <b>{received.total}</b>
                    </TableCell>
                    <TableCell>
                        <b>
                            {received.amount.toLocaleString(undefined, {
                                style: 'currency',
                                currency: 'TRY',
                            })}
                        </b>
                    </TableCell>

                    <TableCell>
                        <b>{cancelled.total}</b>
                    </TableCell>
                    <TableCell>
                        <b>
                            {cancelled.amount.toLocaleString(undefined, {
                                style: 'currency',
                                currency: 'TRY',
                            })}
                        </b>
                    </TableCell>

                    <TableCell>
                        <b>{completed.total}</b>
                    </TableCell>
                    <TableCell>
                        <b>
                            {completed.amount.toLocaleString(undefined, {
                                style: 'currency',
                                currency: 'TRY',
                            })}
                        </b>
                    </TableCell>
                    <TableCell>
                        <b>
                            {purchasePrice.toLocaleString(undefined, {
                                style: 'currency',
                                currency: 'TRY',
                            })}
                        </b>
                    </TableCell>
                    <TableCell>
                        <b>
                            {profit.toLocaleString(undefined, {
                                style: 'currency',
                                currency: 'TRY',
                            })}
                        </b>
                    </TableCell>
                    <TableCell>
                        <b>
                            {expense.toLocaleString(undefined, {
                                style: 'currency',
                                currency: 'TRY',
                            })}
                        </b>
                    </TableCell>
                    <TableCell>
                        <b>
                            {adjustedProfit.toLocaleString(undefined, {
                                style: 'currency',
                                currency: 'TRY',
                            })}
                        </b>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}
const displayableDate = (range) => {
    if (range.end - range.start > 86400000) {
        return `${new Date(range.start).toLocaleDateString()} - ${new Date(range.end).toLocaleDateString()}`
    }
    return new Date(range.start).toLocaleDateString()
}
export const ListOrderReport = () => (
    <RaFixedList
        exporter={orderExporter}
        actions={<OrderListActions />}
        filterDefaultValues={{
            period: 'DAY',
            start: new Date().getTime(),
            end: new Date().getTime(),
        }}
        filters={<Filters justDate={true} />}
        pagination={false}
    >
        <OrderReportGird />
    </RaFixedList>
)

export const OrderReportTranslations = {
    en: {
        orderReports: {
            name: 'Order Report',
            total: 'Total',
            date: 'Date',
            received: 'Received',
            receivedAmount: 'Received Amount',
            completed: 'Delivered',
            completedAmount: 'Delivered Amount',
            cancelled: 'Cancelled',
            cancelledAmount: 'Cancelled Amount',
            fields: {
                endDate: 'End',
                startDate: 'Start',
            },
            period: {
                day: 'DAY',
                month: 'Month',
                week: 'Week',
                year: 'Year',
            },
        },
    },
    tr: {
        orderReports: {
            name: 'Satış Raporu',
            total: 'Toplam',
            date: 'Tarih',
            received: 'Received',
            receivedAmount: 'Received Amount',
            completed: 'Teslim Edildi',
            completedAmount: 'Delivered Amount',
            cancelled: 'Cancelled',
            cancelledAmount: 'Cancelled Amount',
            fields: {
                endDate: 'End',
                startDate: 'Start',
            },
            period: {
                day: 'DAY',
                month: 'Month',
                week: 'Week',
                year: 'Year',
            },
        },
    },
}
