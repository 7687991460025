import React from 'react'
import { Button, Dialog, DialogActions } from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { fetchJson } from '../rest/fetch'
import { SERVER_IP } from '../index'
import CircularProgress from '@mui/material/CircularProgress'
import { useRecordContext, useTranslate } from 'react-admin'

class DeliverOrderView extends React.Component {
    state = {}

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        this.setState({ errorTitle: false })
    }

    handleSend = () => {
        const { record } = this.props
        if (record === undefined) {
            return
        }
        this.setState({ loading: true })
        let id
        if (Array.isArray(record)) {
            id = record.toString()
        } else {
            id = record.id
        }
        fetchJson(`${SERVER_IP}/orders/adminDeliver/${id}`, { method: 'POST' }).then(
            () => {
                this.setState({ loading: false, title: '', body: '' })
                this.props.onResponse(this.props.translate('orders.deliverOrder.done'), false)
            },
            () => {
                this.setState({ loading: false })
                this.props.onResponse(this.props.translate('orders.deliverOrder.failed'), false)
            }
        )
    }

    render() {
        const { loading } = this.state
        const { translate, open, record } = this.props
        let title = translate('orders.deliverOrder.resetTitle')
        if (record !== undefined) {
            if (Array.isArray(record)) {
                title = title + record.toString()
            } else {
                title = title + record.id
            }
        }
        return (
            <Dialog open={open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{translate('orders.deliverOrder.resetMessage')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={loading}
                        variant={'text'}
                        color={'primary'}
                        onClick={() => {
                            this.props.onResponse(null, false)
                        }}
                    >
                        {translate('orders.deliverOrder.cancel')}
                    </Button>
                    <Button disabled={loading} variant={'text'} color={'primary'} onClick={this.handleSend}>
                        {loading && <CircularProgress size={25} thickness={2} />}
                        {translate('orders.deliverOrder.reset')}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export const DeliverOrderTranslations = {
    en: {
        orders: {
            deliverOrder: {
                resetTitle: 'Mark Delivered #',
                resetMessage: 'The selected order(s) will be marked as delivered. Are you sure to continue?',
                cancel: 'No',
                done: 'Order(s) successfully marked delivered',
                failed: 'Failed to mark order(s) as delivered.',
                reset: 'Yes',
                title: 'Mark delivered',
            },
        },
    },
    tr: {
        orders: {
            deliverOrder: {
                resetTitle: 'Teslim Et #',
                resetMessage: 'Seçilen siparişler teslim edildi olarak işaretlenicek,devam etmek istiyormusunuz?',
                cancel: 'Hayır',
                done: 'Siparişler başarı ile teslim edildi olarak ayarlandı',
                failed: 'HATA.',
                reset: 'Evet',
                title: 'Teslim Et',
            },
        },
    },
}

export default function DeliverView(props) {
    const translate = useTranslate()
    let record = useRecordContext()
    if (record === undefined) {
        record = props.record
    }
    return <DeliverOrderView {...props} translate={translate} record={record} />
}
