import React from 'react'

import { Datagrid, Edit, EditButton, SimpleForm, TextField, TextInput } from 'react-admin'
import { RaFixedList } from '../common/ra-fixed-list'

export const ConfigurationsList = () => (
    <RaFixedList>
        <Datagrid bulkActionButtons={false}>
            <TextField source={'key'} />
            <TextField source={'value'} />
            <EditButton />
        </Datagrid>
    </RaFixedList>
)

export const ConfigurationEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source={'key'} disabled />
            <TextInput source={'value'} />
        </SimpleForm>
    </Edit>
)

export const ConfigurationStrings = {
    en: {
        appConfigs: {
            name: 'Configuration',
            fields: {
                key: 'Key',
                value: 'Value',
            },
        },
    },
    tr: {
        appConfigs: {
            name: 'Ayarlar',
            fields: {
                key: 'Anahtar',
                value: 'Değer',
            },
        },
    },
}
