import jsPDF from 'jspdf'
import { useEffect } from 'react'
import { SERVER_IP } from './index'
import download from 'downloadjs'
import { stringify } from 'querystring'

export const pdfExport = (title, data, headers) => {
    const unit = 'pt'
    const size = 'A4'
    const orientation = 'landscape'

    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size)

    doc.setFontSize(18)

    let content = {
        startY: 50,
        head: headers,
        body: data,
    }

    doc.text(title, marginLeft, 40)
    doc.autoTable(content)
    let date = new Date()
    doc.save(`${title}${date.toLocaleDateString()}${date.toLocaleTimeString()}.pdf`.replace(' ', '_'))
}
export const useMountEffect = (fun) => useEffect(fun, [])

export function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n)
}

export function serverExport(resource, sort, filter, pdf = false, onDone, absolute = '') {
    const query = {
        sort: sort ? `${sort.field},${sort.order}` : undefined,
        filter: JSON.stringify(filter),
    }
    const s = stringify(query)
    let url = `${SERVER_IP}/${resource}/export/${pdf ? 'pdf' : 'csv'}?${s}`
    if (absolute) {
        url = encodeURI(absolute)
    }
    downloadFile(url, onDone, pdf)
}

export function downloadFile(url, onDone, pdf) {
    let x = new XMLHttpRequest()
    x.open('GET', url, true)
    x.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('token')}`)
    x.setRequestHeader('Access-Control-Allow-Origin', '*')
    x.setRequestHeader('X-API-KEY', '0500a961-8f5e-4d83-a3b6-174471ef9164')
    x.responseType = 'blob'
    x.onload = function (e) {
        let filename = ''
        let disposition = e.currentTarget.getResponseHeader('Content-Disposition')
        if (disposition && disposition.indexOf('inline') !== -1) {
            let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            let matches = filenameRegex.exec(disposition)
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '')
            }
        }
        let fileType = pdf ? 'application/pdf' : 'text/csv'
        download(e.target.response, filename, fileType)
        onDone()
    }
    x.send()
}
