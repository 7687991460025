import React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslate } from 'react-admin'
import { MuiChipsInput } from 'mui-chips-input'

export function StringArrayInput({ label, helperText, source }) {
    const translate = useTranslate()
    return (
        <Controller
            name={source}
            render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
                <MuiChipsInput
                    style={{ marginBottom: '1em' }}
                    size={'small'}
                    inputRef={ref}
                    error={error !== undefined}
                    helperText={helperText && translate(helperText)}
                    value={Array.isArray(value) ? value : []}
                    onChange={onChange}
                    label={translate(label)}
                />
            )}
        />
    )
}
