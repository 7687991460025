import React, { useEffect, useState } from 'react'
import CardHeader from '@mui/material/CardHeader'
import Card from '@mui/material/Card'
import { CardActions } from '@mui/material'
import { fetchJson } from '../rest/fetch'
import { SERVER_IP } from '../index'
import Button from '@mui/material/Button'
import DeliverOrderView from '../products/DeliverOrderView'
import SendIcon from '@mui/icons-material/LocalShipping'
import RefreshIcon from '@mui/icons-material/Refresh'
import { ShowReceiptView } from './ShowReceiptView'
import OrderTable from './OrderTable'
import { OrderConfirmDialog } from './OrderConfirmDialog'
import { useTranslate } from 'react-admin'
import { useSnackbar } from 'notistack'
import { LoadingButton } from '@mui/lab'

const styles = {
    titleLink: { textDecoration: 'none', color: '#000', width: '100%', overflow: 'scroll' },
    card: { flex: 1, margin: '1em', height: 500, borderWidth: 0 },
    progressContainer: { display: 'table', marginLeft: 'auto', marginRight: 'auto' },
    icon: { float: 'right', width: 64, height: 64, padding: 16, color: '#a43ddb' },
}

export default function PendingOrder() {
    const translate = useTranslate()
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [checked, setChecked] = useState([])
    const [orderId, setOrderId] = useState(-1)
    const [confirmOrder, setConfirmOrder] = useState(-1)
    const [orders, setOrders] = useState([])
    const { enqueueSnackbar } = useSnackbar()
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        const id = setInterval(async () => {
            await loadData()
        }, 5000)
        return () => clearInterval(id)
    }, [])

    const loadData = async () => {
        setLoading(true)
        await fetchJson(`${SERVER_IP}/orders/getPending`)
            .then((res) => {
                return JSON.parse(res.body)
            })
            .then((data) => {
                setOrders(data)
            })
            .catch((e) => {
                console.log(e)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    useEffect(() => {
        setChecked([])
        loadData()
    }, [refresh])

    return (
        <Card
            sx={{
                height: 500,
                flex: 1,
                m: 1,
            }}
            variant={'outlined'}
        >
            <CardHeader
                action={
                    <CardActions>
                        <Button
                            variant={'text'}
                            color={'primary'}
                            startIcon={<SendIcon />}
                            onClick={() => {
                                setOpen(true)
                            }}
                            disabled={checked.length === 0}
                        >
                            {translate('orders.deliverOrder.title')}
                        </Button>
                        <LoadingButton
                            startIcon={<RefreshIcon />}
                            loading={loading}
                            onClick={() => {
                                setRefresh(!refresh)
                            }}
                            color={'secondary'}
                        >
                            Yenile
                        </LoadingButton>
                    </CardActions>
                }
                title={translate('dashboard.pendingOrder.title')}
            />
            {Array.isArray(orders) && orders.length > 0 ? (
                <OrderTable
                    rows={orders}
                    selectionModel={checked}
                    onConfirmClick={setConfirmOrder}
                    setSelectionModel={setChecked}
                    onReceiptClick={setOrderId}
                />
            ) : (
                <div style={{ textAlign: 'center' }}>{translate('dashboard.pendingOrder.noData')}</div>
            )}
            <DeliverOrderView
                open={open}
                onResponse={(message, open) => {
                    setRefresh(!refresh)
                    if (message) {
                        enqueueSnackbar(message)
                    }
                    setOpen(open)
                }}
                record={checked}
            />
            <ShowReceiptView
                open={orderId !== -1}
                orderId={orderId}
                onClose={() => {
                    setOrderId(-1)
                }}
            />

            <OrderConfirmDialog
                id={confirmOrder}
                open={confirmOrder > -1}
                onClose={(status, message) => {
                    if (message != null) {
                        enqueueSnackbar(message)
                    }
                    setConfirmOrder(-1)
                    if (status) {
                        setRefresh(!refresh)
                    }
                }}
            />
        </Card>
    )
}

export const PendingOrderTranslations = {
    en: {
        dashboard: {
            pendingOrder: {
                title: 'Pending Orders',
                noData: 'All caught up!',
            },
        },
    },
    tr: {
        dashboard: {
            pendingOrder: {
                title: 'Bekleyen Siparişler',
                noData: 'Sipariş Yok!',
            },
        },
    },
}
