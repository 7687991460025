import React from 'react'
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    Filter,
    maxLength,
    minLength,
    PasswordInput,
    required,
    Show,
    ShowButton,
    SimpleForm,
    Tab,
    TabbedShowLayout,
    TextField,
    TextInput,
} from 'react-admin'
import UserActions from './UserActions'
import { ListPagination } from '../common/Pagination'
import { TimeField } from '../common/TimeField'
import { SearchInput } from '../common/SearchInput'
import { RaFixedList } from '../common/ra-fixed-list'

const UserFilters = (props) => (
    <Filter {...props}>
        <SearchInput source={'name'} label={'Name'} alwaysOn />
        <SearchInput source={'username'} label={'Username'} alwaysOn />
        <BooleanInput source={'enabled'} />
    </Filter>
)

export const SalePersonCreate = () => (
    <Create>
        <SimpleForm redirect={'list'}>
            <TextInput source={'name'} validate={[required(), maxLength(100)]} />
            <BooleanInput source={'enabled'} />
            <TextInput source={'username'} validate={[required(), minLength(4), maxLength(30)]} />
            <PasswordInput source={'password'} validate={[required(), minLength(6), maxLength(30)]} />
        </SimpleForm>
    </Create>
)

export const SalePersonEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source={'name'} validate={required()} />
            <BooleanInput source={'enabled'} />
            <TextInput source={'username'} disabled validate={[required(), minLength(4)]} />
        </SimpleForm>
    </Edit>
)

export const SalePersonList = () => (
    <RaFixedList
        sort={{ field: 'id', order: 'DESC' }}
        pagination={<ListPagination />}
        perPage={50}
        filters={<UserFilters />}
    >
        <Datagrid bulkActionButtons={false}>
            <TextField source={'name'} />
            <TextField source={'username'} />
            <TimeField source={'creationTime'} />
            <BooleanField source={'enabled'} />
            <ShowButton />
            <EditButton />
            <DeleteWithConfirmButton />
        </Datagrid>
    </RaFixedList>
)

export const SalePersonShow = () => (
    <Show actions={<UserActions />}>
        <TabbedShowLayout>
            <Tab label={'General'}>
                <TextField source={'name'} />
                <TextField source={'username'} />
                <BooleanField source={'enabled'} />
            </Tab>
        </TabbedShowLayout>
    </Show>
)

const Fields = {
    enabled: 'Enabled',
    name: 'Name',
    username: 'Username',
    password: 'Password',
    creationTime: 'Registration Time',
}

export const DepoSaleUserTranslations = {
    en: {
        depoSalePersons: {
            name: 'Depo Sale Persons',
            general: 'General',
            fields: { ...Fields },
        },
    },
    tr: {
        depoSalePersons: {
            name: 'Depo Satış Personeli',
            general: 'Genel',
            fields: { ...Fields },
        },
    },
}
export const SaleUserTranslations = {
    en: {
        salePersons: {
            name: 'Common Sale Persons',
            general: 'General',
            fields: { ...Fields },
        },
    },
    tr: {
        salePersons: {
            name: 'Ortak Satış Personeli',
            general: 'Genel',
            fields: { ...Fields },
        },
    },
}
export const ShopSaleUserTranslations = {
    en: {
        shopSalePersons: {
            name: 'Shop Sale Persons',
            general: 'General',
            fields: { ...Fields },
        },
    },
    tr: {
        shopSalePersons: {
            name: 'Mağaza Satış Personeli',
            general: 'Genel',
            fields: { ...Fields },
        },
    },
}
