import * as React from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { AddressString } from '../common/AddressField'
import Button from '@mui/material/Button'
import ShowIcon from '@mui/icons-material/Visibility'
import { Link } from 'react-router-dom'
import ReceiptIcon from '@mui/icons-material/Receipt'
import { OrderConfirmButton } from './OrderConfirmButton'
import EditIcon from '@mui/icons-material/Edit'
import Box from '@mui/material/Box'

const columns = [
    { field: 'id', headerName: '#', width: 90 },
    { field: 'customer', headerName: 'Muşteri', width: 150, valueGetter: (params) => params.value.name },
    {
        field: 'pendingTime',
        headerName: 'Sipariş Tarihi',
        width: 200,
        valueFormatter: (params) => new Date(params.value).toLocaleString(),
    },
    { field: 'status', headerName: 'Dürüm', width: 150 },
    {
        field: 'driver',
        valueFormatter: (driver) => (driver.value != null ? driver.value.name : ''),
        headerName: 'Sürücü',
        width: 150,
    },
    {
        field: 'address',
        headerName: 'Addres',
        width: 300,
        valueGetter: (params) => AddressString(params.value),
    },
    {
        field: 'finalPrice',
        headerName: 'Toplam',
        width: 150,
        valueFormatter: (params) =>
            params.value.toLocaleString(undefined, {
                style: 'currency',
                currency: 'TRY',
            }),
    },
    {
        field: 'paymentType',
        headerName: 'Ödeme Yöntem',
        width: 200,
        valueFormatter: (params) => (params.value ? params.value : ''),
    },
    {
        field: 'edit',
        disableColumnMenu: true,
        sortable: false,
        headerName: ' ',
        width: 130,
        disableClickEventBubbling: true,
        renderCell: (params) => (
            <Button
                variant={'text'}
                color={'primary'}
                component={Link}
                to={`/orderUpdate/${params.id}`}
                startIcon={<EditIcon />}
            >
                Duzelt
            </Button>
        ),
    },
    {
        field: 'confirm',
        disableColumnMenu: true,
        sortable: false,
        headerName: ' ',
        width: 130,
        disableClickEventBubbling: true,
        renderCell: ({ row }) => <OrderConfirmButton order={row} />,
    },
    {
        field: 'receipt',
        disableColumnMenu: true,
        sortable: false,
        headerName: ' ',
        width: 130,
        disableClickEventBubbling: true,
        renderCell: () => (
            <Button variant={'text'} color={'primary'} startIcon={<ReceiptIcon />}>
                Fiş
            </Button>
        ),
    },

    {
        field: 'show',
        disableColumnMenu: true,
        sortable: false,
        width: 130,
        headerName: ' ',
        disableClickEventBubbling: true,
        renderCell: (params) => (
            <Button
                variant={'text'}
                color={'secondary'}
                startIcon={<ShowIcon />}
                component={Link}
                to={`/orders/${params.id}`}
            >
                Göster
            </Button>
        ),
    },
]

export default function OrderTable({ rows, onReceiptClick, onConfirmClick, selectionModel, setSelectionModel }) {
    function currentlySelected(params) {
        const value = params.colDef.field
        if (value === 'receipt') {
            onReceiptClick(params.id)
        } else if (value === 'confirm') {
            if (params.row.status === 'PENDING') {
                onConfirmClick(params.id)
            }
        }
    }

    return (
        <Box
            sx={{
                height: 420,
                width: '100%,',
            }}
        >
            <DataGrid
                rows={rows}
                columns={columns}
                selectionModel={selectionModel}
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={(newSelection) => {
                    setSelectionModel(newSelection)
                }}
                onCellClick={currentlySelected}
                pageSize={10}
                checkboxSelection
            />
        </Box>
    )
}
