import React, { useEffect, useState } from 'react'
import { Card, CircularProgress } from '@mui/material'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { fetchJson } from '../rest/fetch'
import { SERVER_IP } from '../index'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import DateFnsUtils from '@date-io/date-fns'
import enLocale from 'date-fns/locale/en-US'
import Typography from '@mui/material/Typography'
import trLocale from 'date-fns/locale/tr'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import makeStyles from '@mui/styles/makeStyles'
import ChartCommonInput from './chart-common-input'

const useStyles = makeStyles((theme) => ({
    main: {
        flex: '1',
        margin: '1em',
    },
    titleLink: { textDecoration: 'none', color: 'inherit' },
    card: {
        padding: '16px 0',
        overflow: 'inherit',
        textAlign: 'right',
        height: '470px',
    },
    title: {
        padding: '0 16px',
    },
    value: {
        padding: '0 16px',
        minHeight: 48,
    },
    avatar: {
        background: theme.palette.background.avatar,
    },
    formControl: {
        marginRight: '1em',
    },
}))

const providerOptions = {
    utils: DateFnsUtils,
    locale: enLocale,
}

function sevenDays() {
    let date = new Date()
    date.setTime(date.getTime() - 7 * 24 * 60 * 60 * 1000)
    return date
}

export function SaleCountChart({ refund = false }) {
    const classes = useStyles()
    const [start, setStart] = useState(sevenDays())
    const [end, setEnd] = useState(new Date())
    const [loading, setLoading] = useState(false)
    const [period, setPeriod] = useState('DAY')
    const [data, setData] = useState([])

    useEffect(() => {
        setLoading(true)
        let body = {
            start: start.getTime(),
            end: end.getTime(),
            period: period,
            refund: refund,
        }
        let s = JSON.stringify(body)
        fetchJson(`${SERVER_IP}/saleReports/shop/getCount`, { body: s, method: 'POST' }).then(
            (response) => {
                setLoading(false)
                if (response.json) {
                    setData(response.json)
                }
            },
            () => {
                console.log('Error occurred')
                setLoading(false)
            }
        )
    }, [start, end, period, refund])

    return (
        <div className={classes.main}>
            <Typography variant={'body2'}>{refund ? 'Refunds' : 'Sales'}</Typography>
            <Card className={classes.card} variant={'outlined'}>
                <ChartCommonInput
                    start={start}
                    end={end}
                    setStartDate={setStart}
                    setEndDate={setEnd}
                    period={period}
                    setPeriod={setPeriod}
                />

                {!loading && (
                    <ResponsiveContainer height={'87%'} width={'99%'}>
                        <LineChart data={data}>
                            <CartesianGrid vertical={false} strokeDasharray="3" />
                            <XAxis dataKey="name" tick={{ fill: '#212121' }} stroke={'white'} />
                            <YAxis dataKey={'value'} />
                            <Tooltip />
                            <Line
                                dataKey="value"
                                name={refund ? 'Refunds' : 'Sales'}
                                strokeWidth={2}
                                stroke={refund ? '#3d5afe' : '#4caf50'}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                )}
                {loading && (
                    <div style={{ textAlign: 'center', height: '100%' }}>
                        <CircularProgress color={'primary'} />
                    </div>
                )}
            </Card>
        </div>
    )
}
