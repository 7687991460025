import * as React from 'react'
import { useState } from 'react'
import { serverExport } from '../Util'
import { Button, useRecordContext } from 'react-admin'
import { CircularProgress } from '@mui/material'
import { SERVER_IP } from '../index'
import ReceiptIcon from '@mui/icons-material/Receipt'

export function EFaturaButton() {
    const record = useRecordContext()
    const [loading, setLoading] = useState(false)

    function doExport() {
        setLoading(true)
        serverExport(
            '',
            {},
            null,
            false,
            () => {
                setLoading(false)
            },
            `${SERVER_IP}/orders/efatura/${record.id}`
        )
    }

    return (
        <Button
            startIcon={<ReceiptIcon />}
            label={'E-Fatura'}
            disabled={record === undefined || loading}
            onClick={() => doExport()}
            color={'primary'}
        >
            {loading ? <CircularProgress size={16} color={'secondary'} /> : undefined}
        </Button>
    )
}
