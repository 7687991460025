import React, { useEffect, useState } from 'react'
import { Button, CircularProgress, Dialog, DialogContent } from '@mui/material'
import { fetchJson } from '../rest/fetch'
import { SERVER_IP } from '../index'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import makeStyles from '@mui/styles/makeStyles'
import PrintIcon from '@mui/icons-material/Print'
import { downloadFile } from '../Util'
import { DownloadCircle } from 'mdi-material-ui'
import Stack from '@mui/material/Stack'

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export function ShowReceiptView({ orderId, open, onClose }) {
    const classes = useStyles()
    const [html, setHtml] = useState('')
    const [loading, setLoading] = useState(false)
    const [downloading, setDownloading] = useState(false)

    const handleClose = () => {
        onClose()
    }
    const downloadReceipt = () => {
        setDownloading(true)
        downloadFile(
            `${SERVER_IP}/orders/receipt/print/${orderId}`,
            () => {
                setDownloading(false)
            },
            true
        )
    }

    useEffect(() => {
        if (orderId === undefined || orderId === -1) {
            return
        }
        fetchJson(`${SERVER_IP}/orders/receipt/${orderId}`)
            .then((text) => {
                console.log(text)
                setLoading(false)
                setHtml(text.body)
            })
            .catch((e) => {
                setLoading(false)
                console.log(e)
            })
    }, [orderId])
    const printReceipt = () => {
        window.print()
    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll={'paper'}
            fullScreen
            TransitionComponent={Transition}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {`Receipt #${orderId}`}
                    </Typography>
                    <Stack spacing={2} direction={'row'}>
                        <Button
                            color="inherit"
                            disabled={downloading}
                            startIcon={<PrintIcon />}
                            variant={'outlined'}
                            onClick={printReceipt}
                        >
                            Print
                        </Button>
                        <Button
                            color="primary"
                            disabled={downloading}
                            startIcon={<DownloadCircle />}
                            variant={'contained'}
                            onClick={downloadReceipt}
                        >
                            {downloading && <CircularProgress color={'secondary'} size={24} />}Indir
                        </Button>
                    </Stack>
                </Toolbar>
            </AppBar>
            <DialogContent dividers>
                {loading ? <CircularProgress /> : <div dangerouslySetInnerHTML={{ __html: html }} />}
            </DialogContent>
        </Dialog>
    )
}
