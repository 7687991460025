import React from 'react'
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    Edit,
    EditButton,
    email,
    SimpleForm,
    TextField,
    TextInput,
} from 'react-admin'
import { RaFixedList } from '../common/ra-fixed-list'

export const NotificationEmailList = () => (
    <RaFixedList>
        <Datagrid bulkActionButtons={false}>
            <TextField source={'id'} />
            <TextField source={'email'} />
            <BooleanField source={'enabled'} />
            <EditButton />
        </Datagrid>
    </RaFixedList>
)

const CreateOrEdit = () => (
    <SimpleForm redirect={'list'}>
        <TextInput source={'email'} validate={email()} />
        <BooleanInput source={'enabled'} />
    </SimpleForm>
)
export const NotificationEmailCreate = () => (
    <Create>
        <CreateOrEdit />
    </Create>
)

export const NotificationEmailEdit = () => (
    <Edit>
        <CreateOrEdit />
    </Edit>
)

export const NotificationEmailTranslations = {
    en: {
        notificationEmails: {
            name: 'Emails',
            fields: {
                id: 'ID',
                email: 'Email',
                enabled: 'Enabled',
            },
        },
    },
    tr: {
        notificationEmails: {
            name: 'Emails',
            fields: {
                id: 'ID',
                email: 'Email',
                enabled: 'Aktif?',
            },
        },
    },
}
