import React, { useMemo } from 'react'
import { Translate, usePermissions, useTranslate } from 'react-admin'
import { DepartmentIcon } from '../../categories/Department'
import { CategoryIcon } from '../../categories/Category'
import { CityIcon } from '../../address/City'
import { DistrictIcon } from '../../address/District'
import { TownIcon } from '../../address/Town'
import UserIcon from '@mui/icons-material/AccountCircle'
import DriverIcon from '@mui/icons-material/LocalTaxi'
import { ProductIcon } from '../../products/Product'
import { CampaignIcon } from '../../products/Campaign'
import { OrderIcon } from '../../products/Order'
import EmailIcon from '@mui/icons-material/Email'
import NotificationIcon from '@mui/icons-material/Notifications'
import ConfigIcon from '@mui/icons-material/Style'
import { FaqIcon } from '../../features/faq/Faq'
import LocationIcon from '@mui/icons-material/LocationOn'
import GroceryIcon from '@mui/icons-material/LocalGroceryStore'
import OtherIcon from '@mui/icons-material/Settings'
import ReportIcon from '@mui/icons-material/Assessment'
import DashboardIcon from '@mui/icons-material/Dashboard'
import Iconify from '../../components/iconify'
import { ToysRounded } from '@mui/icons-material'

const categories = (translate: Translate) => [
    { title: translate('resources.departments.name'), path: '/departments', icon: <DepartmentIcon /> },
    { title: translate('resources.categories.name'), path: '/categories', icon: <CategoryIcon /> },
]
const locations = (translate: Translate) => [
    { title: translate('resources.cities.name'), path: '/cities', icon: <CityIcon /> },
    { title: translate('resources.districts.name'), path: '/districts', icon: <DistrictIcon /> },
    { title: translate('resources.towns.name'), path: '/towns', icon: <TownIcon /> },
]
const users = (translate: Translate) => [
    { title: translate('resources.customers.name'), path: '/customers', icon: <UserIcon /> },
    {
        title: translate('resources.rankings.name'),
        path: '/rankings',
        icon: <Iconify icon={'solar:ranking-bold-duotone'} />,
    },
    { title: translate('resources.drivers.name'), path: '/drivers', icon: <DriverIcon /> },
    // { title: translate('resources.salePersons.name'), path: '/salePersons', icon: <SalePersonIcon /> },
    // { title: translate('resources.depoSalePersons.name'), path: '/depoSalePersons', icon: <DepoSaleIcon /> },
    // { title: translate('resources.shopSalePersons.name'), path: '/shopSalePersons', icon: <SaleIcon /> },
]
const products = (translate: Translate) => [
    { title: translate('resources.products.name'), path: '/products', icon: <ProductIcon /> },
    { title: translate('resources.productUnits.name'), path: '/productUnits', icon: <ToysRounded /> },
    { title: translate('resources.campaigns.name'), path: '/campaigns', icon: <CampaignIcon /> },
    { title: translate('resources.orders.name'), path: '/orders', icon: <OrderIcon /> },
]
const reports = (translate: Translate) => [
    { title: translate('resources.orderReports.name'), path: '/orderReports', icon: <OrderIcon /> },
    // { title: translate('resources.saleReports/shop.name'), path: '/saleReports/shop', icon: <SaleIcon /> },
    // { title: translate('resources.saleReports/depo.name'), path: '/saleReports/depo', icon: <DepoSaleIcon /> },
]
const others = (translate: Translate) => [
    { title: translate('resources.notificationEmails.name'), path: '/notificationEmails', icon: <EmailIcon /> },
    { title: translate('resources.notifications.name'), path: '/notifications', icon: <NotificationIcon /> },
    { title: translate('resources.appConfigs.name'), path: '/appConfigs', icon: <ConfigIcon /> },
    {
        title: translate('resources.faq.name'),
        path: '/faq',
        icon: <FaqIcon />,
    },
]
// const refunds = (translate: Translate) => [
//     { title: translate('resources.refunds.name'), path: 'refunds', icon: <SaleIcon /> },
//     { title: translate('resources.depoRefunds.name'), path: 'depoRefunds', icon: <DepoSaleIcon /> },
// ]
//
// const sales = (translate: Translate) => [
//     { title: translate('resources.sales.name'), path: 'sales', icon: <SaleIcon /> },
//     { title: translate('resources.depoSales.name'), path: 'depoSales', icon: <DepoSaleIcon /> },
// ]
const commons = (translate: Translate) => [
    { subheader: translate('resources.products.name'), items: [products(translate)[0]] },
    { subheader: translate('resources.productUnits.name'), items: [products(translate)[1]] },
    { subheader: translate('resources.orders.name'), items: [products(translate)[2]] },
    { subheader: translate('resources.customers.name'), items: [users(translate)[0]] },
]
const salePersonItems = (translate: Translate) => [
    // { subheader: translate('menu.sales'), icon: <DepoSaleIcon />, items: sales(translate), key: 'sales' },
    // { subheader: translate('menu.refunds'), icon: <RefundIcon />, items: refunds(translate), key: 'refunds' },
    ...commons(translate),
]

const depoSalePersonItems = (translate: Translate) => [
    // { subheader: translate('resources.depoSales.name'), items: [sales(translate)[1]] },
    ...commons(translate),
]
const shopSalePersonItems = (translate: Translate) => [
    // { subheader: translate('resources.sales.name'), items: [sales(translate)[0]] },
    ...commons(translate),
]

const adminItems = (translate: Translate) => [
    {
        subheader: 'Catalog',
        items: [
            { title: 'Locations', children: locations(translate), icon: <LocationIcon />, path: '/cities' },
            { title: 'Departments', path: '/departments', icon: <DepartmentIcon />, children: categories(translate) },
            {
                title: translate('menu.products'),
                path: '/products',
                icon: <GroceryIcon />,
                children: products(translate),
            },
        ],
    },
    {
        subheader: 'Management',
        items: [
            { children: users(translate), icon: <UserIcon />, title: translate('menu.users'), path: '/users' },
            // { children: sales(translate), icon: <SaleIcon />, path: '/sales', title: 'Sales' },
            // { children: refunds(translate), icon: <RefundIcon />, path: '/refunds', title: 'Refunds' },
            { children: others(translate), icon: <OtherIcon />, path: '/notificationEmails', title: 'Others' },
            { children: reports(translate), icon: <ReportIcon />, path: '/orderReports', title: 'Reporting' },
        ],
        key: 'users',
    },
]

export function useNavData() {
    const translate = useTranslate()
    const { permissions } = usePermissions()
    return useMemo(() => {
        let items = []
        if (permissions === 'ROLE_ADMIN') {
            items = adminItems(translate)
        } else if (permissions === 'ROLE_DEPO_SALE_PERSON') {
            items = depoSalePersonItems(translate)
        } else if (permissions === 'ROLE_SALE_PERSON') {
            items = salePersonItems(translate)
        } else if (permissions === 'ROLE_SHOP_SALE_PERSON') {
            items = shopSalePersonItems(translate)
        } else {
            return []
        }

        return [
            {
                subheader: translate('ra.page.dashboard'),
                items: [
                    {
                        icon: <DashboardIcon />,
                        path: '/',
                        title: translate('ra.page.dashboard'),
                    },
                ],
            },
            ...items,
        ]
    }, [permissions])
}

export const MenuTranslations = {
    en: {
        menu: {
            locations: 'Locations',
            users: 'Users',
            products: 'Items',
            reports: 'Reports',
            others: 'Others',
            sales: 'Sales',
            refunds: 'Refunds',
        },
    },
    tr: {
        menu: {
            locations: 'Bölgeler',
            users: 'Kullanıcılar',
            products: 'Ürünler',
            reports: 'Raporlar',
            others: 'Diğer',
            sales: 'Satış',
            refunds: 'Iade',
        },
    },
}
