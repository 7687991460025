import React from 'react'
import Icon from '@mui/icons-material/Category'
import {
    AutocompleteArrayInput,
    BooleanInput,
    ChipField,
    Create,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    Filter,
    ImageField,
    maxLength,
    ReferenceArrayField,
    ReferenceArrayInput,
    ReferenceField,
    ReferenceInput,
    required,
    SelectInput,
    Show,
    ShowButton,
    SimpleShowLayout,
    SingleFieldList,
    TabbedForm,
    TextField,
    TextInput,
    useRecordContext,
} from 'react-admin'
import ImageUpload from '../common/ImageUpload'
import { ListPagination } from '../common/Pagination'
import { SearchInput } from '../common/SearchInput'
import { ProductReOrderButton } from '../products/ProductReorder'
import { RaFixedList } from '../common/ra-fixed-list'

export const CategoryIcon = Icon
export const CategoryCreate = () => (
    <Create>
        <CreateOrEdit />
    </Create>
)

export const CategoryEdit = () => (
    <Edit>
        <CreateOrEdit />
    </Edit>
)

const CategoryFilters = (props) => (
    <Filter {...props}>
        <SearchInput source={'name.english'} alwaysOn label={'English Name'} />
        <SearchInput source={'name.turkish'} alwaysOn label={'Turkish Name'} />
        <BooleanInput source={'onlyPanel'} />
        <ReferenceInput source={'department.id'} perPage={1000} reference={'departments'}>
            <SelectInput optionText={'name.turkish'} />
        </ReferenceInput>
    </Filter>
)
export const CategoryList = () => (
    <RaFixedList
        sort={{ field: 'id', order: 'DESC' }}
        pagination={<ListPagination />}
        perPage={50}
        filters={<CategoryFilters />}
    >
        <CategoryGrid />
    </RaFixedList>
)

export const CategoryGrid = () => (
    <Datagrid bulkActionButtons={false}>
        <TextField source={'name.turkish'} />
        <ReferenceField source={'department.id'} reference={'departments'}>
            <TextField source={'name.turkish'} />
        </ReferenceField>
        <AlternateDepartmentsField source={'alternateDepartments'} label={'More Departments'} />
        <TextField source={'description.turkish'} />
        <ShowButton />
        <ProductReOrderButton />
        <EditButton />
        <DeleteWithConfirmButton />
    </Datagrid>
)
export const CategoryShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source={'name.english'} />
            <TextField source={'name.turkish'} />
            <TextField source={'description.english'} />
            <TextField source={'description.turkish'} />
            <ReferenceField source={'department.id'} reference={'departments'}>
                <TextField source={'name.turkish'} />
            </ReferenceField>
            <ImageField source={'image'} />
        </SimpleShowLayout>
    </Show>
)
const CreateOrEdit = () => (
    <TabbedForm redirect={'list'} syncWithLocation={false}>
        <TabbedForm.Tab label={'resources.categories.general'}>
            <TextInput source={'name.english'} validate={[required(), maxLength(255)]} />
            <TextInput source={'name.turkish'} validate={[required(), maxLength(255)]} />

            <ReferenceInput source={'department.id'} perPage={1000} reference={'departments'} validate={required()}>
                <SelectInput optionText={'name.turkish'} />
            </ReferenceInput>
            <ReferenceArrayInput source={'alternateDepartments'} label={'More Departments'} reference={'departments'}>
                <AutocompleteArrayInput source={'id'} size={'small'} optionText={'name.turkish'} />
            </ReferenceArrayInput>
            <TextInput source={'description.english'} fullWidth validate={[required(), maxLength(1000000)]} />
            <TextInput source={'description.turkish'} fullWidth validate={[required(), maxLength(1000000)]} />

            <BooleanInput source={'onlyPanel'} />
        </TabbedForm.Tab>
        <TabbedForm.Tab label={'resources.categories.fields.image'}>
            <ImageUpload source={'image'} required={false} />
        </TabbedForm.Tab>
    </TabbedForm>
)

export const AlternateDepartmentsField = ({ source, ...props }) => {
    const record = useRecordContext()
    if (record === undefined || record == null) {
        return <></>
    }

    return (
        <ReferenceArrayField source={source} {...props} record={record} reference="departments">
            <SingleFieldList>
                <ChipField source={'name.turkish'} />
            </SingleFieldList>
        </ReferenceArrayField>
    )
}
export const CategoryTranslations = {
    en: {
        categories: {
            name: 'Categories',
            general: 'General',
            fields: {
                name: {
                    english: 'English Title',
                    turkish: 'Turkish Title',
                },
                image: 'Image',
                description: {
                    english: 'Description',
                    turkish: 'Turkish Description',
                },
                department: {
                    id: 'Department',
                },
            },
        },
    },
    tr: {
        categories: {
            name: 'Kategoriler',
            general: 'Genel',
            fields: {
                name: {
                    english: 'İngilizce Adı',
                    turkish: 'Türkçe Adı',
                },
                image: 'Resim',
                description: {
                    english: 'İngilizce Açıklama',
                    turkish: 'Türkçe Açıklama',
                },
                department: {
                    id: 'Departman',
                },
            },
        },
    },
}
